/*=================================================================================

    Theme Name: Cloin
    Theme URI: http://moradhamdy.com/morad/cloin-html
    Description: Lead Generation Landing Page Template
    Author: Morad
    Author URI: http://moradhamdy.com/
    Version: 1.0

    SCSS Stylesheet

===================================================================================*/
/**
 *  styles.css file is divided as the following:
 *  
 *  ( 01 ) - General Settings
 *  ( 02 ) - General Contents
 *  ( 03 ) - Header
 *  ( 04 ) - Content
 *  ( 05 ) - Footer
 *
 */
/**
 * First, including commons.css which contains
 * css-helpers codes
*/
/**
 * File Name: css-helpers.css
 */
/* =====================================================================
 *   helpers-placeholders.scss
 * =================================================================== */
/* BG Gradients */
/* Opacity Values */
.opacity-05 {
    opacity: 0.05;
  }
  
  .opacity-10 {
    opacity: 0.10;
  }
  
  .opacity-15 {
    opacity: 0.15;
  }
  
  .opacity-20 {
    opacity: 0.20;
  }
  
  .opacity-25 {
    opacity: 0.25;
  }
  
  .opacity-30 {
    opacity: 0.30;
  }
  
  .opacity-35 {
    opacity: 0.35;
  }
  
  .opacity-40 {
    opacity: 0.40;
  }
  
  .opacity-45 {
    opacity: 0.45;
  }
  
  .opacity-50 {
    opacity: 0.50;
  }
  
  .opacity-55 {
    opacity: 0.55;
  }
  
  .opacity-60 {
    opacity: 0.60;
  }
  
  .opacity-65 {
    opacity: 0.65;
  }
  
  .opacity-70 {
    opacity: 0.70;
  }
  
  .opacity-75 {
    opacity: 0.75;
  }
  
  .opacity-80 {
    opacity: 0.80;
  }
  
  .opacity-85 {
    opacity: 0.85;
  }
  
  .opacity-90 {
    opacity: 0.90;
  }
  
  .opacity-95 {
    opacity: 0.95;
  }
  
  .opacity-100 {
    opacity: 1;
  }
  
  /*  [ 01 ] - Colors
  ===================== */
  /* Opacity Values */
  /* Colors */
  .color-theme {
    color: #863260;
  }
  
  .color-bg-theme {
    background-color: #863260;
  }
  
  .color-heading {
    color: #222;
  }
  
  .color-bg-heading {
    background-color: #222;
  }
  
  .color-dark {
    color: #222;
  }
  
  .color-bg-dark {
    background-color: #222;
  }
  
  .color-white {
    color: #fff;
  }
  
  .color-bg-white {
    background-color: #fff;
  }
  
  .color-grey {
    color: #666;
  }
  
  .color-bg-grey {
    background-color: #666;
  }
  
  .color-red {
    color: #eb0808;
  }
  
  .color-green {
    color: #1a9e0f;
  }
  
  .color-bg-gradient-1 {
    background-color: #863260;
    background-image: -webkit-linear-gradient(270deg, #863260 0%, #036c31 100%);
    background-image: -moz-linear-gradient(270deg, #863260 0%, #036c31 100%);
    background-image: -o-linear-gradient(270deg, #863260 0%, #036c31 100%);
    background-image: linear-gradient(270deg, #863260 0%, #036c31 100%);
  }
  
  .color-bg-gradient-2 {
    background-color: #2ebf6d;
    background-image: -webkit-linear-gradient(45deg, #2ebf6d 0%, #0937f6 100%);
    background-image: -moz-linear-gradient(45deg, #2ebf6d 0%, #0937f6 100%);
    background-image: -o-linear-gradient(45deg, #2ebf6d 0%, #0937f6 100%);
    background-image: linear-gradient(45deg, #2ebf6d 0%, #0937f6 100%);
  }
  
  /* Font Sizes */
  .font-size-10 {
    font-size: 10px;
  }
  
  .font-size-11 {
    font-size: 11px;
  }
  
  .font-size-12 {
    font-size: 12px;
  }
  
  .font-size-13 {
    font-size: 13px;
  }
  
  .font-size-14 {
    font-size: 14px;
  }
  
  .font-size-15 {
    font-size: 15px;
  }
  
  .font-size-16 {
    font-size: 16px;
  }
  
  .font-size-17 {
    font-size: 17px;
  }
  
  .font-size-18 {
    font-size: 18px;
  }
  
  .font-size-19 {
    font-size: 19px;
  }
  
  .font-size-20 {
    font-size: 20px;
  }
  
  .font-size-22 {
    font-size: 22px;
  }
  
  @media (max-width: 1400px) {
    .font-size-22 {
      font-size: 18px;
    }
  }
  
  @media (max-width: 480px) {
    .font-size-22 {
      font-size: 14px;
    }
  }
  
  /* Text Alignment */
  @media (max-width: 992px) {
    .md-text-center {
      text-align: center;
    }
    .md-text-right {
      text-align: right;
    }
    .md-text-left {
      text-align: left;
    }
  }
  
  @media (max-width: 768px) {
    .sm-text-center {
      text-align: center;
    }
    .sm-text-right {
      text-align: right;
    }
    .sm-text-left {
      text-align: left;
    }
  }
  
  @media (max-width: 480px) {
    .xs-text-center {
      text-align: center;
    }
    .xs-text-right {
      text-align: right;
    }
    .xs-text-left {
      text-align: left;
    }
  }
  
  @media (max-width: 320px) {
    .md-text-center {
      text-align: center;
    }
    .md-text-right {
      text-align: right;
    }
    .md-text-left {
      text-align: left;
    }
  }
  
  /*  [ 02 ] - Text Styles
  ========================== */
  .font-weight-100 {
    font-weight: 100;
  }
  
  .font-weight-200 {
    font-weight: 200;
  }
  
  .font-weight-300 {
    font-weight: 300;
  }
  
  .font-weight-400 {
    font-weight: 400;
  }
  
  .font-weight-500 {
    font-weight: 500;
  }
  
  .font-weight-600 {
    font-weight: 600;
  }
  
  .font-weight-700 {
    font-weight: 700;
  }
  
  .font-weight-800 {
    font-weight: 800;
  }
  
  .font-weight-900 {
    font-weight: 900;
  }
  
  /*  [ 02 ] - Padding
  ====================== */
  /* Top Padding */
  .pt-0 {
    padding-top: 0;
  }
  
  .pt-10 {
    padding-top: 10px;
  }
  
  .pt-20 {
    padding-top: 20px;
  }
  
  .pt-30 {
    padding-top: 30px;
  }
  
  .pt-40 {
    padding-top: 40px;
  }
  
  .pt-50 {
    padding-top: 50px;
  }
  
  .pt-60 {
    padding-top: 60px;
  }
  
  .pt-70 {
    padding-top: 70px;
  }
  
  .pt-80 {
    padding-top: 80px;
  }
  
  .pt-90 {
    padding-top: 90px;
  }
  
  .pt-100 {
    padding-top: 100px;
  }
  
  .pt-110 {
    padding-top: 110px;
  }
  
  .pt-120 {
    padding-top: 120px;
  }
  
  .pt-130 {
    padding-top: 130px;
  }
  
  .pt-140 {
    padding-top: 140px;
  }
  
  /* Bottom Padding */
  .pb-0 {
    padding-bottom: 0;
  }
  
  .pb-10 {
    padding-bottom: 10px;
  }
  
  .pb-20 {
    padding-bottom: 20px;
  }
  
  .pb-30 {
    padding-bottom: 30px;
  }
  
  .pb-40 {
    padding-bottom: 40px;
  }
  
  .pb-50 {
    padding-bottom: 50px;
  }
  
  .pb-60 {
    padding-bottom: 60px;
  }
  
  .pb-70 {
    padding-bottom: 70px;
  }
  
  .pb-80 {
    padding-bottom: 80px;
  }
  
  .pb-90 {
    padding-bottom: 90px;
  }
  
  .pb-100 {
    padding-bottom: 100px;
  }
  
  .pb-110 {
    padding-bottom: 110px;
  }
  
  .pb-120 {
    padding-bottom: 120px;
  }
  
  .pb-130 {
    padding-bottom: 130px;
  }
  
  .pb-140 {
    padding-bottom: 140px;
  }
  
  /* Left Padding */
  .pl-0 {
    padding-left: 0;
  }
  
  .pl-10 {
    padding-left: 10px;
  }
  
  .pl-20 {
    padding-left: 20px;
  }
  
  .pl-30 {
    padding-left: 30px;
  }
  
  .pl-40 {
    padding-left: 40px;
  }
  
  .pl-50 {
    padding-left: 50px;
  }
  
  .pl-60 {
    padding-left: 60px;
  }
  
  .pl-70 {
    padding-left: 70px;
  }
  
  .pl-80 {
    padding-left: 80px;
  }
  
  .pl-90 {
    padding-left: 90px;
  }
  
  .pl-100 {
    padding-left: 100px;
  }
  
  .pl-110 {
    padding-left: 110px;
  }
  
  .pl-120 {
    padding-left: 120px;
  }
  
  .pl-130 {
    padding-left: 130px;
  }
  
  .pl-140 {
    padding-left: 140px;
  }
  
  /* Right Padding */
  .pr-0 {
    padding-right: 0;
  }
  
  .pr-10 {
    padding-right: 10px;
  }
  
  .pr-20 {
    padding-right: 20px;
  }
  
  .pr-30 {
    padding-right: 30px;
  }
  
  .pr-40 {
    padding-right: 40px;
  }
  
  .pr-50 {
    padding-right: 50px;
  }
  
  .pr-60 {
    padding-right: 60px;
  }
  
  .pr-70 {
    padding-right: 70px;
  }
  
  .pr-80 {
    padding-right: 80px;
  }
  
  .pr-90 {
    padding-right: 90px;
  }
  
  .pr-100 {
    padding-right: 100px;
  }
  
  .pr-110 {
    padding-right: 110px;
  }
  
  .pr-120 {
    padding-right: 120px;
  }
  
  .pr-130 {
    padding-right: 130px;
  }
  
  .pr-140 {
    padding-right: 140px;
  }
  
  /* Top Bottom Padding */
  .ptb-0 {
    padding: 0;
  }
  
  .ptb-10 {
    padding: 10px 0;
  }
  
  .ptb-20 {
    padding: 20px 0;
  }
  
  .ptb-30 {
    padding: 30px 0;
  }
  
  .ptb-40 {
    padding: 40px 0;
  }
  
  .ptb-50 {
    padding: 50px 0;
  }
  
  .ptb-60 {
    padding: 60px 0;
  }
  
  .ptb-70 {
    padding: 70px 0;
  }
  
  .ptb-80 {
    padding: 80px 0;
  }
  
  .ptb-90 {
    padding: 90px 0;
  }
  
  .ptb-100 {
    padding: 100px 0;
  }
  
  .ptb-110 {
    padding: 110px 0;
  }
  
  .ptb-120 {
    padding: 120px 0;
  }
  
  .ptb-130 {
    padding: 130px 0;
  }
  
  .ptb-140 {
    padding: 140px 0;
  }
  
  /* Left Right Padding */
  .plr-0 {
    padding: 0;
  }
  
  .plr-10 {
    padding: 0 10px;
  }
  
  .plr-20 {
    padding: 0 20px;
  }
  
  .plr-30 {
    padding: 0 30px;
  }
  
  .plr-40 {
    padding: 0 40px;
  }
  
  .plr-50 {
    padding: 0 50px;
  }
  
  .plr-60 {
    padding: 0 60px;
  }
  
  .plr-70 {
    padding: 0 70px;
  }
  
  .plr-80 {
    padding: 0 80px;
  }
  
  .plr-90 {
    padding: 0 90px;
  }
  
  .plr-100 {
    padding: 0 100px;
  }
  
  .plr-110 {
    padding: 0 110px;
  }
  
  .plr-120 {
    padding: 0 120px;
  }
  
  .plr-130 {
    padding: 0 130px;
  }
  
  .plr-140 {
    padding: 0 140px;
  }
  
  /* Responsive Padding [ col-md- ] */
  @media (max-width: 992px) {
    /* Responsive Top Padding */
    .pt-md-0 {
      padding-top: 0;
    }
    .pt-md-10 {
      padding-top: 10px;
    }
    .pt-md-20 {
      padding-top: 20px;
    }
    .pt-md-30 {
      padding-top: 30px;
    }
    .pt-md-40 {
      padding-top: 40px;
    }
    .pt-md-50 {
      padding-top: 50px;
    }
    .pt-md-60 {
      padding-top: 60px;
    }
    .pt-md-70 {
      padding-top: 70px;
    }
    .pt-md-80 {
      padding-top: 80px;
    }
    .pt-md-90 {
      padding-top: 90px;
    }
    .pt-md-100 {
      padding-top: 100px;
    }
    .pt-md-110 {
      padding-top: 110px;
    }
    .pt-md-120 {
      padding-top: 120px;
    }
    .pt-md-130 {
      padding-top: 130px;
    }
    .pt-md-140 {
      padding-top: 140px;
    }
    /* Responsive Bottom Padding */
    .pb-md-0 {
      padding-bottom: 0;
    }
    .pb-md-10 {
      padding-bottom: 10px;
    }
    .pb-md-20 {
      padding-bottom: 20px;
    }
    .pb-md-30 {
      padding-bottom: 30px;
    }
    .pb-md-40 {
      padding-bottom: 40px;
    }
    .pb-md-50 {
      padding-bottom: 50px;
    }
    .pb-md-60 {
      padding-bottom: 60px;
    }
    .pb-md-70 {
      padding-bottom: 70px;
    }
    .pb-md-80 {
      padding-bottom: 80px;
    }
    .pb-md-90 {
      padding-bottom: 90px;
    }
    .pb-md-100 {
      padding-bottom: 100px;
    }
    .pb-md-110 {
      padding-bottom: 110px;
    }
    .pb-md-120 {
      padding-bottom: 120px;
    }
    .pb-md-130 {
      padding-bottom: 130px;
    }
    .pb-md-140 {
      padding-bottom: 140px;
    }
    /* Responsive Left Padding */
    .pl-md-0 {
      padding-left: 0;
    }
    .pl-md-10 {
      padding-left: 10px;
    }
    .pl-md-20 {
      padding-left: 20px;
    }
    .pl-md-30 {
      padding-left: 30px;
    }
    .pl-md-40 {
      padding-left: 40px;
    }
    .pl-md-50 {
      padding-left: 50px;
    }
    .pl-md-60 {
      padding-left: 60px;
    }
    .pl-md-70 {
      padding-left: 70px;
    }
    .pl-md-80 {
      padding-left: 80px;
    }
    .pl-md-90 {
      padding-left: 90px;
    }
    .pl-md-100 {
      padding-left: 100px;
    }
    .pl-md-110 {
      padding-left: 110px;
    }
    .pl-md-120 {
      padding-left: 120px;
    }
    .pl-md-130 {
      padding-left: 130px;
    }
    .pl-md-140 {
      padding-left: 140px;
    }
    /* Responsive Right Padding */
    .pr-md-0 {
      padding-right: 0;
    }
    .pr-md-10 {
      padding-right: 10px;
    }
    .pr-md-20 {
      padding-right: 20px;
    }
    .pr-md-30 {
      padding-right: 30px;
    }
    .pr-md-40 {
      padding-right: 40px;
    }
    .pr-md-50 {
      padding-right: 50px;
    }
    .pr-md-60 {
      padding-right: 60px;
    }
    .pr-md-70 {
      padding-right: 70px;
    }
    .pr-md-80 {
      padding-right: 80px;
    }
    .pr-md-90 {
      padding-right: 90px;
    }
    .pr-md-100 {
      padding-right: 100px;
    }
    .pr-md-110 {
      padding-right: 110px;
    }
    .pr-md-120 {
      padding-right: 120px;
    }
    .pr-md-130 {
      padding-right: 130px;
    }
    .pr-md-140 {
      padding-right: 140px;
    }
    /* Responsive Top Bottom Padding */
    .ptb-md-0 {
      padding: 0;
    }
    .ptb-md-10 {
      padding: 10px 0;
    }
    .ptb-md-20 {
      padding: 20px 0;
    }
    .ptb-md-30 {
      padding: 30px 0;
    }
    .ptb-md-40 {
      padding: 40px 0;
    }
    .ptb-md-50 {
      padding: 50px 0;
    }
    .ptb-md-60 {
      padding: 60px 0;
    }
    .ptb-md-70 {
      padding: 70px 0;
    }
    .ptb-md-80 {
      padding: 80px 0;
    }
    .ptb-md-90 {
      padding: 90px 0;
    }
    .ptb-md-100 {
      padding: 100px 0;
    }
    .ptb-md-110 {
      padding: 110px 0;
    }
    .ptb-md-120 {
      padding: 120px 0;
    }
    .ptb-md-130 {
      padding: 130px 0;
    }
    .ptb-md-140 {
      padding: 140px 0;
    }
    /* Responsive Left Right Padding */
    .plr-md-0 {
      padding: 0;
    }
    .plr-md-10 {
      padding: 0 10px;
    }
    .plr-md-20 {
      padding: 0 20px;
    }
    .plr-md-30 {
      padding: 0 30px;
    }
    .plr-md-40 {
      padding: 0 40px;
    }
    .plr-md-50 {
      padding: 0 50px;
    }
    .plr-md-60 {
      padding: 0 60px;
    }
    .plr-md-70 {
      padding: 0 70px;
    }
    .plr-md-80 {
      padding: 0 80px;
    }
    .plr-md-90 {
      padding: 0 90px;
    }
    .plr-md-100 {
      padding: 0 100px;
    }
    .plr-md-110 {
      padding: 0 110px;
    }
    .plr-md-120 {
      padding: 0 120px;
    }
    .plr-md-130 {
      padding: 0 130px;
    }
    .plr-md-140 {
      padding: 0 140px;
    }
  }
  
  /*  [ 03 ] - Margin
  ===================== */
  /* Top Margin */
  .mt-0 {
    margin-top: 0;
  }
  
  .mt-10 {
    margin-top: 10px;
  }
  
  .mt-20 {
    margin-top: 20px;
  }
  
  .mt-30 {
    margin-top: 30px;
  }
  
  .mt-40 {
    margin-top: 40px;
  }
  
  .mt-50 {
    margin-top: 50px;
  }
  
  .mt-60 {
    margin-top: 60px;
  }
  
  .mt-70 {
    margin-top: 70px;
  }
  
  .mt-80 {
    margin-top: 80px;
  }
  
  .mt-90 {
    margin-top: 90px;
  }
  
  .mt-100 {
    margin-top: 100px;
  }
  
  .mt-110 {
    margin-top: 110px;
  }
  
  .mt-120 {
    margin-top: 120px;
  }
  
  .mt-130 {
    margin-top: 130px;
  }
  
  .mt-140 {
    margin-top: 140px;
  }
  
  /* Bottom Margin */
  .mb-0 {
    margin-bottom: 0;
  }
  
  .mb-10 {
    margin-bottom: 10px;
  }
  
  .mb-20 {
    margin-bottom: 20px;
  }
  
  .mb-30 {
    margin-bottom: 30px;
  }
  
  .mb-40 {
    margin-bottom: 40px;
  }
  
  .mb-50 {
    margin-bottom: 50px;
  }
  
  .mb-60 {
    margin-bottom: 60px;
  }
  
  .mb-70 {
    margin-bottom: 70px;
  }
  
  .mb-80 {
    margin-bottom: 80px;
  }
  
  .mb-90 {
    margin-bottom: 90px;
  }
  
  .mb-100 {
    margin-bottom: 100px;
  }
  
  .mb-110 {
    margin-bottom: 110px;
  }
  
  .mb-120 {
    margin-bottom: 120px;
  }
  
  .mb-130 {
    margin-bottom: 130px;
  }
  
  .mb-140 {
    margin-bottom: 140px;
  }
  
  /* Left Margin */
  .ml-0 {
    margin-left: 0;
  }
  
  .ml-10 {
    margin-left: 10px;
  }
  
  .ml-20 {
    margin-left: 20px;
  }
  
  .ml-30 {
    margin-left: 30px;
  }
  
  .ml-40 {
    margin-left: 40px;
  }
  
  .ml-50 {
    margin-left: 50px;
  }
  
  .ml-60 {
    margin-left: 60px;
  }
  
  .ml-70 {
    margin-left: 70px;
  }
  
  .ml-80 {
    margin-left: 80px;
  }
  
  .ml-90 {
    margin-left: 90px;
  }
  
  .ml-100 {
    margin-left: 100px;
  }
  
  .ml-110 {
    margin-left: 110px;
  }
  
  .ml-120 {
    margin-left: 120px;
  }
  
  .ml-130 {
    margin-left: 130px;
  }
  
  .ml-140 {
    margin-left: 140px;
  }
  
  /* Right Margin */
  .mr-0 {
    margin-right: 0;
  }
  
  .mr-10 {
    margin-right: 10px;
  }
  
  .mr-20 {
    margin-right: 20px;
  }
  
  .mr-30 {
    margin-right: 30px;
  }
  
  .mr-40 {
    margin-right: 40px;
  }
  
  .mr-50 {
    margin-right: 50px;
  }
  
  .mr-60 {
    margin-right: 60px;
  }
  
  .mr-70 {
    margin-right: 70px;
  }
  
  .mr-80 {
    margin-right: 80px;
  }
  
  .mr-90 {
    margin-right: 90px;
  }
  
  .mr-100 {
    margin-right: 100px;
  }
  
  .mr-110 {
    margin-right: 110px;
  }
  
  .mr-120 {
    margin-right: 120px;
  }
  
  .mr-130 {
    margin-right: 130px;
  }
  
  .mr-140 {
    margin-right: 140px;
  }
  
  /* Top Bottom Margin */
  .mtb-0 {
    margin: 0;
  }
  
  .mtb-10 {
    margin: 10px 0;
  }
  
  .mtb-20 {
    margin: 20px 0;
  }
  
  .mtb-30 {
    margin: 30px 0;
  }
  
  .mtb-40 {
    margin: 40px 0;
  }
  
  .mtb-50 {
    margin: 50px 0;
  }
  
  .mtb-60 {
    margin: 60px 0;
  }
  
  .mtb-70 {
    margin: 70px 0;
  }
  
  .mtb-80 {
    margin: 80px 0;
  }
  
  .mtb-90 {
    margin: 90px 0;
  }
  
  .mtb-100 {
    margin: 100px 0;
  }
  
  .mtb-110 {
    margin: 110px 0;
  }
  
  .mtb-120 {
    margin: 120px 0;
  }
  
  .mtb-130 {
    margin: 130px 0;
  }
  
  .mtb-140 {
    margin: 140px 0;
  }
  
  /* Left Right Margin */
  .mlr-0 {
    margin: 0;
  }
  
  .mlr-10 {
    margin: 0 10px;
  }
  
  .mlr-20 {
    margin: 0 20px;
  }
  
  .mlr-30 {
    margin: 0 30px;
  }
  
  .mlr-40 {
    margin: 0 40px;
  }
  
  .mlr-50 {
    margin: 0 50px;
  }
  
  .mlr-60 {
    margin: 0 60px;
  }
  
  .mlr-70 {
    margin: 0 70px;
  }
  
  .mlr-80 {
    margin: 0 80px;
  }
  
  .mlr-90 {
    margin: 0 90px;
  }
  
  .mlr-100 {
    margin: 0 100px;
  }
  
  .mlr-110 {
    margin: 0 110px;
  }
  
  .mlr-120 {
    margin: 0 120px;
  }
  
  .mlr-130 {
    margin: 0 130px;
  }
  
  .mlr-140 {
    margin: 0 140px;
  }
  
  /* Responsive Margin [ That added for small devices < 992px ] */
  @media (max-width: 992px) {
    /* Responsive Top Margin */
    .mt-md-0 {
      margin-top: 0;
    }
    .mt-md-10 {
      margin-top: 10px;
    }
    .mt-md-20 {
      margin-top: 20px;
    }
    .mt-md-30 {
      margin-top: 30px;
    }
    .mt-md-40 {
      margin-top: 40px;
    }
    .mt-md-50 {
      margin-top: 50px;
    }
    .mt-md-60 {
      margin-top: 60px;
    }
    .mt-md-70 {
      margin-top: 70px;
    }
    .mt-md-80 {
      margin-top: 80px;
    }
    .mt-md-90 {
      margin-top: 90px;
    }
    .mt-md-100 {
      margin-top: 100px;
    }
    .mt-md-110 {
      margin-top: 110px;
    }
    .mt-md-120 {
      margin-top: 120px;
    }
    .mt-md-130 {
      margin-top: 130px;
    }
    .mt-md-140 {
      margin-top: 140px;
    }
    /* Responsive Bottom Margin */
    .mb-md-0 {
      margin-bottom: 0;
    }
    .mb-md-10 {
      margin-bottom: 10px;
    }
    .mb-md-20 {
      margin-bottom: 20px;
    }
    .mb-md-30 {
      margin-bottom: 30px;
    }
    .mb-md-40 {
      margin-bottom: 40px;
    }
    .mb-md-50 {
      margin-bottom: 50px;
    }
    .mb-md-60 {
      margin-bottom: 60px;
    }
    .mb-md-70 {
      margin-bottom: 70px;
    }
    .mb-md-80 {
      margin-bottom: 80px;
    }
    .mb-md-90 {
      margin-bottom: 90px;
    }
    .mb-md-100 {
      margin-bottom: 100px;
    }
    .mb-md-110 {
      margin-bottom: 110px;
    }
    .mb-md-120 {
      margin-bottom: 120px;
    }
    .mb-md-130 {
      margin-bottom: 130px;
    }
    .mb-md-140 {
      margin-bottom: 140px;
    }
    /* Responsive Left Margin */
    .ml-md-0 {
      margin-left: 0;
    }
    .ml-md-10 {
      margin-left: 10px;
    }
    .ml-md-20 {
      margin-left: 20px;
    }
    .ml-md-30 {
      margin-left: 30px;
    }
    .ml-md-40 {
      margin-left: 40px;
    }
    .ml-md-50 {
      margin-left: 50px;
    }
    .ml-md-60 {
      margin-left: 60px;
    }
    .ml-md-70 {
      margin-left: 70px;
    }
    .ml-md-80 {
      margin-left: 80px;
    }
    .ml-md-90 {
      margin-left: 90px;
    }
    .ml-md-100 {
      margin-left: 100px;
    }
    .ml-md-110 {
      margin-left: 110px;
    }
    .ml-md-120 {
      margin-left: 120px;
    }
    .ml-md-130 {
      margin-left: 130px;
    }
    .ml-md-140 {
      margin-left: 140px;
    }
    /* Responsive Right Margin */
    .mr-md-0 {
      margin-right: 0;
    }
    .mr-md-10 {
      margin-right: 10px;
    }
    .mr-md-20 {
      margin-right: 20px;
    }
    .mr-md-30 {
      margin-right: 30px;
    }
    .mr-md-40 {
      margin-right: 40px;
    }
    .mr-md-50 {
      margin-right: 50px;
    }
    .mr-md-60 {
      margin-right: 60px;
    }
    .mr-md-70 {
      margin-right: 70px;
    }
    .mr-md-80 {
      margin-right: 80px;
    }
    .mr-md-90 {
      margin-right: 90px;
    }
    .mr-md-100 {
      margin-right: 100px;
    }
    .mr-md-110 {
      margin-right: 110px;
    }
    .mr-md-120 {
      margin-right: 120px;
    }
    .mr-md-130 {
      margin-right: 130px;
    }
    .mr-md-140 {
      margin-right: 140px;
    }
    /* Responsive Top Bottom Margin */
    .mtb-md-0 {
      margin: 0;
    }
    .mtb-md-10 {
      margin: 10px 0;
    }
    .mtb-md-20 {
      margin: 20px 0;
    }
    .mtb-md-30 {
      margin: 30px 0;
    }
    .mtb-md-40 {
      margin: 40px 0;
    }
    .mtb-md-50 {
      margin: 50px 0;
    }
    .mtb-md-60 {
      margin: 60px 0;
    }
    .mtb-md-70 {
      margin: 70px 0;
    }
    .mtb-md-80 {
      margin: 80px 0;
    }
    .mtb-md-90 {
      margin: 90px 0;
    }
    .mtb-md-100 {
      margin: 100px 0;
    }
    .mtb-md-110 {
      margin: 110px 0;
    }
    .mtb-md-120 {
      margin: 120px 0;
    }
    .mtb-md-130 {
      margin: 130px 0;
    }
    .mtb-md-140 {
      margin: 140px 0;
    }
    /* Responsive Left Right Margin */
    .mlr-md-0 {
      margin: 0;
    }
    .mlr-md-10 {
      margin: 0 10px;
    }
    .mlr-md-20 {
      margin: 0 20px;
    }
    .mlr-md-30 {
      margin: 0 30px;
    }
    .mlr-md-40 {
      margin: 0 40px;
    }
    .mlr-md-50 {
      margin: 0 50px;
    }
    .mlr-md-60 {
      margin: 0 60px;
    }
    .mlr-md-70 {
      margin: 0 70px;
    }
    .mlr-md-80 {
      margin: 0 80px;
    }
    .mlr-md-90 {
      margin: 0 90px;
    }
    .mlr-md-100 {
      margin: 0 100px;
    }
    .mlr-md-110 {
      margin: 0 110px;
    }
    .mlr-md-120 {
      margin: 0 120px;
    }
    .mlr-md-130 {
      margin: 0 130px;
    }
    .mlr-md-140 {
      margin: 0 140px;
    }
  }
  
  /* Responsive Margin [ That added for small devices < 768px ] */
  @media (max-width: 768px) {
    /* Responsive Bottom Margin */
    .mb-sm-0 {
      margin-bottom: 0;
    }
    .mb-sm-10 {
      margin-bottom: 10px;
    }
    .mb-sm-20 {
      margin-bottom: 20px;
    }
    .mb-sm-30 {
      margin-bottom: 30px;
    }
    .mb-sm-40 {
      margin-bottom: 40px;
    }
    .mb-sm-50 {
      margin-bottom: 50px;
    }
    .mb-sm-60 {
      margin-bottom: 60px;
    }
    .mb-sm-70 {
      margin-bottom: 70px;
    }
    .mb-sm-80 {
      margin-bottom: 80px;
    }
    .mb-sm-90 {
      margin-bottom: 90px;
    }
    .mb-sm-100 {
      margin-bottom: 100px;
    }
    .mb-sm-110 {
      margin-bottom: 110px;
    }
    .mb-sm-120 {
      margin-bottom: 120px;
    }
    .mb-sm-130 {
      margin-bottom: 130px;
    }
    .mb-sm-140 {
      margin-bottom: 140px;
    }
    /* Responsive Top Margin */
    .mt-sm-0 {
      margin-top: 0;
    }
    .mt-sm-10 {
      margin-top: 10px;
    }
    .mt-sm-20 {
      margin-top: 20px;
    }
    .mt-sm-30 {
      margin-top: 30px;
    }
    .mt-sm-40 {
      margin-top: 40px;
    }
    .mt-sm-50 {
      margin-top: 50px;
    }
    .mt-sm-60 {
      margin-top: 60px;
    }
    .mt-sm-70 {
      margin-top: 70px;
    }
    .mt-sm-80 {
      margin-top: 80px;
    }
    .mt-sm-90 {
      margin-top: 90px;
    }
    .mt-sm-100 {
      margin-top: 100px;
    }
    .mt-sm-110 {
      margin-top: 110px;
    }
    .mt-sm-120 {
      margin-top: 120px;
    }
    .mt-sm-130 {
      margin-top: 130px;
    }
    .mt-sm-140 {
      margin-top: 140px;
    }
  }
  
  /* Responsive Margin [ That added for small devices < 480px ] */
  @media (max-width: 480px) {
    /* Responsive Bottom Margin */
    .mb-xs-0 {
      margin-bottom: 0;
    }
    .mb-xs-10 {
      margin-bottom: 10px;
    }
    .mb-xs-20 {
      margin-bottom: 20px;
    }
    .mb-xs-30 {
      margin-bottom: 30px;
    }
    .mb-xs-40 {
      margin-bottom: 40px;
    }
    .mb-xs-50 {
      margin-bottom: 50px;
    }
    .mb-xs-60 {
      margin-bottom: 60px;
    }
    .mb-xs-70 {
      margin-bottom: 70px;
    }
    .mb-xs-80 {
      margin-bottom: 80px;
    }
    .mb-xs-90 {
      margin-bottom: 90px;
    }
    .mb-xs-100 {
      margin-bottom: 100px;
    }
    .mb-xs-110 {
      margin-bottom: 110px;
    }
    .mb-xs-120 {
      margin-bottom: 120px;
    }
    .mb-xs-130 {
      margin-bottom: 130px;
    }
    .mb-xs-140 {
      margin-bottom: 140px;
    }
    /* Responsive Top Margin */
    .mt-xs-0 {
      margin-top: 0;
    }
    .mt-xs-10 {
      margin-top: 10px;
    }
    .mt-xs-20 {
      margin-top: 20px;
    }
    .mt-xs-30 {
      margin-top: 30px;
    }
    .mt-xs-40 {
      margin-top: 40px;
    }
    .mt-xs-50 {
      margin-top: 50px;
    }
    .mt-xs-60 {
      margin-top: 60px;
    }
    .mt-xs-70 {
      margin-top: 70px;
    }
    .mt-xs-80 {
      margin-top: 80px;
    }
    .mt-xs-90 {
      margin-top: 90px;
    }
    .mt-xs-100 {
      margin-top: 100px;
    }
    .mt-xs-110 {
      margin-top: 110px;
    }
    .mt-xs-120 {
      margin-top: 120px;
    }
    .mt-xs-130 {
      margin-top: 130px;
    }
    .mt-xs-140 {
      margin-top: 140px;
    }
  }
  
  /*  [ 03 ] - Heights
  ====================== */
  .h-0 {
    height: 0px;
  }
  
  .h-10 {
    height: 10px;
  }
  
  .h-20 {
    height: 20px;
  }
  
  .h-30 {
    height: 30px;
  }
  
  .h-40 {
    height: 40px;
  }
  
  .h-50 {
    height: 50px;
  }
  
  .h-60 {
    height: 60px;
  }
  
  .h-70 {
    height: 70px;
  }
  
  .h-80 {
    height: 80px;
  }
  
  .h-90 {
    height: 90px;
  }
  
  .h-100 {
    height: 100px;
  }
  
  .h-120 {
    height: 120px;
  }
  
  .h-140 {
    height: 140px;
  }
  
  .h-160 {
    height: 160px;
  }
  
  .h-180 {
    height: 180px;
  }
  
  .h-200 {
    height: 200px;
  }
  
  .h-220 {
    height: 220px;
  }
  
  .h-240 {
    height: 240px;
  }
  
  .h-260 {
    height: 260px;
  }
  
  .h-280 {
    height: 280px;
  }
  
  .h-300 {
    height: 300px;
  }
  
  .h-320 {
    height: 320px;
  }
  
  .h-340 {
    height: 340px;
  }
  
  .h-360 {
    height: 360px;
  }
  
  .h-380 {
    height: 380px;
  }
  
  .h-400 {
    height: 400px;
  }
  
  .h-420 {
    height: 420px;
  }
  
  .h-440 {
    height: 440px;
  }
  
  .h-460 {
    height: 460px;
  }
  
  .h-480 {
    height: 480px;
  }
  
  .h-500 {
    height: 500px;
  }
  
  .h-520 {
    height: 520px;
  }
  
  .h-540 {
    height: 540px;
  }
  
  .h-560 {
    height: 560px;
  }
  
  .h-580 {
    height: 580px;
  }
  
  .h-600 {
    height: 600px;
  }
  
  .h-620 {
    height: 620px;
  }
  
  .h-640 {
    height: 640px;
  }
  
  .h-660 {
    height: 660px;
  }
  
  .h-680 {
    height: 680px;
  }
  
  .h-700 {
    height: 700px;
  }
  
  .h-720 {
    height: 720px;
  }
  
  .h-740 {
    height: 740px;
  }
  
  .h-760 {
    height: 760px;
  }
  
  .h-780 {
    height: 780px;
  }
  
  .h-800 {
    height: 800px;
  }
  
  .h-820 {
    height: 820px;
  }
  
  .h-840 {
    height: 840px;
  }
  
  .h-860 {
    height: 860px;
  }
  
  .h-880 {
    height: 880px;
  }
  
  .h-900 {
    height: 900px;
  }
  
  @media (max-width: 992px) {
    .h-md-0 {
      height: 0px;
    }
    .h-md-10 {
      height: 10px;
    }
    .h-md-20 {
      height: 20px;
    }
    .h-md-30 {
      height: 30px;
    }
    .h-md-40 {
      height: 40px;
    }
    .h-md-50 {
      height: 50px;
    }
    .h-md-60 {
      height: 60px;
    }
    .h-md-70 {
      height: 70px;
    }
    .h-md-80 {
      height: 80px;
    }
    .h-md-90 {
      height: 90px;
    }
    .h-md-100 {
      height: 100px;
    }
    .h-md-120 {
      height: 120px;
    }
    .h-md-140 {
      height: 140px;
    }
    .h-md-160 {
      height: 160px;
    }
    .h-md-180 {
      height: 180px;
    }
    .h-md-200 {
      height: 200px;
    }
    .h-md-220 {
      height: 220px;
    }
    .h-md-240 {
      height: 240px;
    }
    .h-md-260 {
      height: 260px;
    }
    .h-md-280 {
      height: 280px;
    }
    .h-md-300 {
      height: 300px;
    }
    .h-md-320 {
      height: 320px;
    }
    .h-md-340 {
      height: 340px;
    }
    .h-md-360 {
      height: 360px;
    }
    .h-md-380 {
      height: 380px;
    }
    .h-md-400 {
      height: 400px;
    }
    .h-md-420 {
      height: 420px;
    }
    .h-md-440 {
      height: 440px;
    }
    .h-md-460 {
      height: 460px;
    }
    .h-md-480 {
      height: 480px;
    }
    .h-md-500 {
      height: 500px;
    }
    .h-md-520 {
      height: 520px;
    }
    .h-md-540 {
      height: 540px;
    }
    .h-md-560 {
      height: 560px;
    }
    .h-md-580 {
      height: 580px;
    }
    .h-md-600 {
      height: 600px;
    }
    .h-md-620 {
      height: 620px;
    }
    .h-md-640 {
      height: 640px;
    }
    .h-md-660 {
      height: 660px;
    }
    .h-md-680 {
      height: 680px;
    }
    .h-md-700 {
      height: 700px;
    }
    .h-md-720 {
      height: 720px;
    }
    .h-md-740 {
      height: 740px;
    }
    .h-md-760 {
      height: 760px;
    }
    .h-md-780 {
      height: 780px;
    }
    .h-md-800 {
      height: 800px;
    }
    .h-md-820 {
      height: 820px;
    }
    .h-md-840 {
      height: 840px;
    }
    .h-md-860 {
      height: 860px;
    }
    .h-md-880 {
      height: 880px;
    }
    .h-md-900 {
      height: 900px;
    }
  }
  
  /*  [ 04 ] - Width
  ==================== */
  .w-100p {
    width: 100%;
  }
  
  /*  [ 05 ] - Hidden Elements
  ============================== */
  @media (max-width: 480px) {
    .hidden-u-480 {
      display: none;
    }
  }
  
  @media (min-width: 480px) {
    .hidden-a-480 {
      display: none;
    }
  }
  
  /**
   * -------------------------------------------------------------------------------
   * ( 01 ) - General Settings
   * -------------------------------------------------------------------------------
   *
   *  This part contains the general settings that you can change like general
   *  font-family, color, headings settings, paragraphs, .. etc.
   *
   * ------
   *
   *  General Basics divided to the following sections:
   *
   *    |
   *    |-->  [ 01 ] - Small Reset
   *    |-->  [ 02 ] - Body
   *    |-->  [ 03 ] - General Links
   *    |-->  [ 04 ] - Headings
   *    |-->  [ 05 ] - Paragraphs
   *    |-->  [ 06 ] - Images
   *    |-->  [ 07 ] - Strong
   *    |-->  [ 08 ] - Texts Highlighting Selection
   *    |-->  [ 09 ] - Text Colored
   *    |-->  [ 10 ] - Form Settings
   *    |-->  [ 11 ] - Page Title
   *    |-->  [ 12 ] - Table
   *    |-->  [ 13 ] - Loading Progress
   *
   */
  /*  [ 01 ] - Small Reset
  =========================== */
  body, ul, ol, h1, h2, h3, h4, h5, h6, form, li, p, input, select, legend, textarea, fieldset {
    margin: 0;
    padding: 0;
  }
  
  /*  [ 02 ] - Body
  ==================== */
  body {
    width: 100%;
    position: relative;
    font: 16px/1.75 "Open Sans", "Almarai", sans-serif;
    background: #fff;
    color: #888;
  }
  
  .font-heading-primary {
    font-family: "Titillium Web", "Almarai", sans-serif;
  }
  
  .font-heading-secondary {
    font-family: "Titillium Web", "Almarai", sans-serif;
  }
  
  #full-container {
    overflow: hidden;
    position: relative;
    max-width: 100%;
    background-color: #fff;
  }
  
  html.scroll-lock {
    overflow: hidden;
  }
  
  /* Custom Default Content Width */
  /* Boxed Width */
  body.boxed {
    background-color: #eee;
  }
  
  body.boxed #full-container,
  body.boxed .header-bar,
  body.boxed .header-bar-wrap,
  body.boxed #scroll-progress {
    width: 1300px;
  }
  
  body.boxed .header-bar-wrap {
    margin: auto;
  }
  
  body.boxed #full-container {
    margin: 60px auto;
    border-radius: 5px;
    box-shadow: 0 9px 30px 5px rgba(136, 136, 136, 0.2);
  }
  
  body.boxed #full-container .header-bar,
  body.boxed #full-container #scroll-progress {
    margin: auto;
  }
  
  body.boxed #full-container .header-bar {
    width: 100%;
  }
  
  @media (max-width: 1300px) {
    body.boxed #full-container {
      margin: 0;
      border-radius: 0;
    }
    body.boxed #full-container,
    body.boxed .header-bar,
    body.boxed .header-bar-wrap,
    body.boxed #scroll-progress {
      width: auto;
    }
  }
  
  /* Space between Bootstrap columns */
  .col-xs-1, .col-sm-1, .col-md-1, .col-lg-1, .col-xs-2, .col-sm-2, .col-md-2, .col-lg-2, .col-xs-3, .col-sm-3, .col-md-3, .col-lg-3, .col-xs-4, .col-sm-4, .col-md-4, .col-lg-4, .col-xs-5, .col-sm-5, .col-md-5, .col-lg-5, .col-xs-6, .col-sm-6, .col-md-6, .col-lg-6, .col-xs-7, .col-sm-7, .col-md-7, .col-lg-7, .col-xs-8, .col-sm-8, .col-md-8, .col-lg-8, .col-xs-9, .col-sm-9, .col-md-9, .col-lg-9, .col-xs-10, .col-sm-10, .col-md-10, .col-lg-10, .col-xs-11, .col-sm-11, .col-md-11, .col-lg-11, .col-xs-12, .col-sm-12, .col-md-12, .col-lg-12 {
    padding-left: 15px;
    padding-right: 15px;
  }
  
  /* Removing last element margin bottom in column */
  [class*="col-"] p:last-child,
  [class*="col-"] .img-preview:last-child,
  [class*="col-"] .video-preview:last-child,
  [class*="col-"] .entry:last-child,
  [class*="col-"] .list:last-child,
  [class*="col-"] .connect-box:last-child {
    margin-bottom: 0;
  }
  
  /*  [ 03 ] - General Links
  ============================= */
  a,
  a img {
    outline: none !important;
    border: none;
  }
  
  a {
    text-decoration: none;
    color: #863260;
    transition: all 0.2s;
  }
  
  a:hover {
    text-decoration: none;
    color: #222;
  }
  
  a:focus {
    color: #863260;
    text-decoration: none;
  }
  
  p a {
    border-bottom: 1px dotted transparent;
  }
  
  p a:hover {
    text-decoration: none;
    border-bottom: 1px dotted #863260;
  }
  
  /*  [ 04 ] - Headings
  ======================== */
  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    margin-top: 0;
    margin-bottom: 13px;
    font-family: "Titillium Web", "Almarai", sans-serif;
    font-weight: 600;
    line-height: 1.4;
    color: #222;
  }
  
  h1.capitalized,
  h2.capitalized,
  h3.capitalized,
  h4.capitalized,
  h5.capitalized,
  h6.capitalized {
    text-transform: capitalize !important;
  }
  
  h1 a,
  h2 a,
  h3 a,
  h4 a,
  h5 a,
  h6 a {
    text-decoration: none;
    color: #222;
    transition: all 0.15s;
  }
  
  h1 a:hover,
  h2 a:hover,
  h3 a:hover,
  h4 a:hover,
  h5 a:hover,
  h6 a:hover {
    color: #863260;
  }
  
  h4,
  h5,
  h6 {
    margin-bottom: 10px;
    font-weight: 600;
  }
  
  h1 {
    font-size: 42px;
  }
  
  h2 {
    font-size: 36px;
  }
  
  h3 {
    font-size: 30px;
  }
  
  h4 {
    font-size: 24px;
  }
  
  h5 {
    font-size: 18px;
  }
  
  h6 {
    font-size: 16px;
  }
  
  .text-white h1, .text-white h2, .text-white h3, .text-white h4, .text-white h5, .text-white h6 {
    color: #fff;
  }
  
  /* Heading Weights */
  .heading-200 {
    font-weight: 300;
  }
  
  @media (max-width: 1400px) {
    h1 {
      font-size: 37.8px;
    }
    h2 {
      font-size: 32.4px;
    }
    h3 {
      font-size: 27px;
    }
    h4 {
      font-size: 21.6px;
    }
    h5 {
      font-size: 16.2px;
    }
    h6 {
      font-size: 14.4px;
    }
  }
  
  @media (max-width: 480px) {
    h1 {
      font-size: 35.7px;
    }
    h2 {
      font-size: 30.6px;
    }
    h3 {
      font-size: 25.5px;
    }
    h4 {
      font-size: 22.8px;
    }
    h5 {
      font-size: 17.1px;
    }
    h6 {
      font-size: 15.2px;
    }
  }
  
  /*  [ 05 ] - Paragraphs
  ========================== */
  p {
    margin-bottom: 22px;
  }
  
  .dark p {
    color: #fff;
  }
  
  /*  [ 06 ] - Images
  ====================== */
  img {
    max-width: 100%;
  }
  
  /*  [ 07 ] - Strong
  ====================== */
  strong {
    color: #222;
    font-weight: 600;
  }
  
  /*  [ 08 ] - Texts Highlighting Selection
  ============================================ */
  ::-moz-selection {
    background: #c0eaf5;
    color: #222;
  }
  
  ::selection {
    background: #c0eaf5;
    color: #222;
  }
  
  /*  [ 09 ] - Text Colored
  ============================ */
  .colored {
    color: #863260;
  }
  
  /*  [ 10 ] - Form Settings
  ============================ */
  form {
    position: relative;
    font-family: "Open Sans", "Almarai", sans-serif !important;
  }
  
  form [class*="col-"]:last-child .form-group:last-child {
    overflow: hidden;
    margin-bottom: 0 !important;
  }
  
  form .form-group {
    position: relative;
    margin-bottom: 0;
  }
  
  form .form-group + .form-group {
    margin-top: 20px;
  }
  
  form.form-inline .form-group + .form-group {
    margin-top: 0;
  }
  
  input,
  input.form-control,
  button[type="submit"],
  button[type="submit"].form-control,
  textarea,
  textarea.form-control,
  select,
  select.form-control {
    padding: 0 20px;
    width: 100%;
    height: 45px;
    line-height: 45px;
    font-size: 14px;
    font-weight: 500;
    outline: none;
    box-shadow: inset 0px 0 0 2px #eee;
    box-shadow: none;
    border: none;
    border-radius: 5px;
    transition: all 0.2s;
    color: #222;
    background-color: #eee;
  }
  
  textarea,
  textarea.form-control {
    height: 200px;
    padding-top: 16px;
    padding-bottom: 16px;
    line-height: 27px !important;
    border-radius: 15px;
    resize: vertical;
  }
  
  input:focus,
  input.form-control:focus,
  textarea:focus,
  textarea.form-control:focus,
  select:focus,
  select.form-control:focus {
    box-shadow: inset 0 0 0 2px #ccc;
    background: #fff;
    color: #222;
  }
  
  button:focus,
  button.form-control:focus {
    box-shadow: none;
    border-color: transparent;
  }
  
  .field-icon {
    position: absolute !important;
    left: 20px;
    top: 13px;
    font-size: 18px;
    color: #222;
  }
  
  .field-icon + input[type="text"],
  .field-icon + input[type="email"],
  .field-icon + textarea {
    text-indent: 28px;
  }
  
  .form-inline .form-control {
    width: 100%;
  }
  
  abbr {
    cursor: help;
  }
  
  label {
    vertical-align: top;
    margin-bottom: 0;
    color: #222;
    font-weight: 500;
    font-size: 16px;
    margin-bottom: 12px;
  }
  
  label.error {
    display: block;
    margin: 5px 0 0;
    text-align: left;
    font-size: 12px;
    font-weight: 400;
    color: #f33;
  }
  
  input.error, input.error:focus,
  textarea.error,
  textarea.error:focus,
  select.error,
  select.error:focus {
    box-shadow: 0 0 0 2px #f33;
    background: #fff;
  }
  
  input[type="submit"],
  input[type="submit"].form-control,
  button[type="submit"],
  button[type="submit"].form-control {
    position: relative;
    z-index: 0;
    display: inline-block;
    padding: 0 40px;
    height: 50px;
    line-height: 50px;
    cursor: pointer;
    font-weight: 600;
    font-size: 18px;
    border: none;
    border-radius: 5px;
    transition: all 0.2s;
    color: #fff !important;
    background-color: #863260;
  }
  
  @media (max-width: 480px) {
    input[type="submit"],
    input[type="submit"].form-control,
    button[type="submit"],
    button[type="submit"].form-control {
      padding: 0 30px;
    }
  }
  
  input[type="submit"]:not(.btn),
  input[type="submit"].form-control:not(.btn),
  button[type="submit"]:not(.btn),
  button[type="submit"].form-control:not(.btn) {
    box-shadow: none;
  }
  
  input[type="submit"].icon,
  input[type="submit"].form-control.icon,
  button[type="submit"].icon,
  button[type="submit"].form-control.icon {
    position: absolute;
    right: -1px;
    top: -1px;
    display: flex;
    align-items: center;
    padding: 0;
    width: 80px;
    height: 52px;
    font-size: 17px;
    border-radius: 0 999px 999px 0;
    box-shadow: 0px 15px 15px 4px rgba(0, 0, 0, 0.1);
  }
  
  .form-inline .form-control[type="submit"] {
    width: auto;
  }
  
  input[type="submit"]:hover,
  input[type="submit"].form-control:hover,
  button[type="submit"]:hover,
  button[type="submit"].form-control:hover {
    background-color: #04b753;
  }
  
  input[type="submit"]:focus,
  input[type="submit"].form-control:focus {
    box-shadow: none;
  }
  
  /**
   * -------------------------------------------------------------------------------
   * ( 02 ) - General Contents
   * -------------------------------------------------------------------------------
   *
   *  This part contains the basic general contents like box preview, image  
   *  preview, flat section .. etc.
   *
   * ------
   *
   *  General Contents divided to the following sections:
   *
   *    |
   *    |-->  [ 01 ] - Section Flat
   *    |-->  [ 02 ] - Image BG
   *    |-->  [ 03 ] - Slider Image BG
   *    |-->  [ 04 ] - Image Preview
   *    |-->  [ 05 ] - Box Info
   *    |-->  [ 06 ] - Divider Space
   *    |-->  [ 07 ] - Magnific Popup
   *    |-->  [ 08 ] - Icons
   *    |-->  [ 09 ] - Buttons
   *    |-->  [ 10 ] - Social Icons
   *    |-->  [ 11 ] - Scroll Top Icon
   *
   */
  /*  [ 01 ] - Section Flat
  =========================== */
  .section-flat {
    width: 100%;
    position: relative;
  }
  
  .section-flat .section-content {
    width: 100%;
    padding: 120px 0 120px;
    position: relative;
    clear: both;
  }
  
  .section-flat .section-content > .container-fluid {
    padding: 0;
  }
  
  /* Section Title */
  .section-title {
    margin-bottom: 40px;
  }
  
  .section-title h5 {
    margin-bottom: 12px;
    font-weight: 500;
    color: #863260;
  }
  
  .section-title p {
    padding-top: 5px;
    font-size: 18px;
  }
  
  .section-title.text-white h5 {
    opacity: 0.8;
    color: #fff;
  }
  
  .section-title.text-white h1 {
    color: #fff;
  }
  
  .section-title.text-white p {
    opacity: 0.7;
    color: #fff;
  }
  
  .col-md-8 .section-title.text-center {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  
  .col-md-8 .section-title.text-center h2 {
    width: 80%;
  }
  
  @media (max-width: 768px) {
    .col-md-8 .section-title.text-center h2 {
      width: 100%;
    }
  }
  
  .col-md-8 .section-title.text-center p {
    width: 75%;
  }
  
  @media (max-width: 768px) {
    .col-md-8 .section-title.text-center p {
      width: 100%;
    }
  }
  
  /* Section Parallax */
  .section-parallax {
    width: 100%;
    position: relative;
    background-color: #ccc;
    background-position: center center;
    background-size: cover;
    background-repeat: no-repeat;
  }
  
  .section-parallax .section-content {
    width: 100%;
    height: 100%;
    position: relative;
    padding: 120px 0;
    z-index: 2;
    background-attachment: fixed;
    background-position: top center;
    background-size: cover;
    background-repeat: no-repeat;
  }
  
  .section-parallax .bg-element {
    position: absolute;
    width: 100%;
    height: 100%;
    background: no-repeat top center / cover;
  }
  
  .section-parallax .bg-element + img {
    display: none;
  }
  
  @media (max-width: 992px) {
    .section-parallax > .bg-element {
      background-position: initial !important;
    }
  }
  
  /*  [ 02 ] - Image BG
  ======================= */
  .img-bg {
    overflow: hidden;
    position: relative;
    display: block;
    height: 330px;
    border-radius: 4px;
    background: no-repeat center / cover;
    background-color: #ddd;
    transition: all 0.5s;
  }
  
  .img-bg .bg-element {
    position: absolute;
    width: 100%;
    height: 100%;
    background: no-repeat top center / cover;
    transition: all 0.4s ease;
  }
  
  .img-bg > a {
    position: relative;
    display: block;
    height: 100%;
  }
  
  .img-bg > a img {
    display: block;
  }
  
  .img-bg .overlay {
    position: absolute;
    top: 0;
    left: 0;
    width: calc(100% + 1px);
    height: 100%;
    background-color: rgba(0, 0, 0, 0.75);
  }
  
  .img-bg .overlay,
  .img-bg .overlay .overlay-inner {
    transition: all 0.3s ease;
  }
  
  .img-bg .overlay .overlay-inner {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    width: 100%;
    height: 100%;
    padding: 30px;
    transition: all 0.2s 0.2s;
  }
  
  .img-bg .overlay .overlay-inner > * {
    position: relative;
    z-index: 10;
  }
  
  .img-bg .overlay .overlay-inner .sub-title {
    color: #863260;
    position: relative;
    text-transform: uppercase;
    font-weight: 400;
  }
  
  .img-bg .overlay .overlay-inner h1, .img-bg .overlay .overlay-inner h2, .img-bg .overlay .overlay-inner h3, .img-bg .overlay .overlay-inner h4, .img-bg .overlay .overlay-inner h5, .img-bg .overlay .overlay-inner h6 {
    color: #fff;
  }
  
  .img-bg .overlay .overlay-inner h1:last-child, .img-bg .overlay .overlay-inner h2:last-child, .img-bg .overlay .overlay-inner h3:last-child, .img-bg .overlay .overlay-inner h4:last-child, .img-bg .overlay .overlay-inner h5:last-child, .img-bg .overlay .overlay-inner h6:last-child {
    margin-bottom: 0;
  }
  
  /*  [ 03 ] - Slider Image BG
  ============================== */
  .slider-img-bg .bg-element {
    position: absolute;
    width: 100%;
    height: 100%;
    background: no-repeat top center / cover;
    transition: all 0.4s ease;
  }
  
  .slider-img-bg .slide > img {
    display: none;
  }
  
  /*  [ 04 ] - Image Preview
  ============================ */
  .img-preview {
    position: relative;
  }
  
  .img-preview img {
    max-width: 100%;
  }
  
  /*  [ 05 ] - Box Info
  ======================= */
  .box-info {
    position: relative;
  }
  
  .box-info.text-white {
    color: #fff;
  }
  
  .box-info.text-white p {
    color: rgba(255, 255, 255, 0.5);
  }
  
  .box-info .box-icon {
    float: left;
    margin: 0 20px 0 0;
  }
  
  .box-info .box-icon img {
    margin-top: 6px;
  }
  
  .box-info .box-icon i {
    margin-top: 1px;
  }
  
  .box-info .box-content h5 {
    margin-bottom: 10px;
  }
  
  @media (min-width: 992px) {
    .reversed .box-icon {
      float: right;
      margin-left: 20px;
      margin-right: 0;
    }
    .reversed .box-content {
      text-align: right;
    }
  }
  
  /*  [ 06 ] - Divider Space
  ============================ */
  [class*="divider-"] {
    display: block;
    overflow: hidden;
    clear: both;
  }
  
  .divider-140 {
    height: 140px;
  }
  
  .divider-130 {
    height: 130px;
  }
  
  .divider-120 {
    height: 120px;
  }
  
  .divider-110 {
    height: 110px;
  }
  
  .divider-100 {
    height: 100px;
  }
  
  .divider-90 {
    height: 90px;
  }
  
  .divider-80 {
    height: 80px;
  }
  
  .divider-70 {
    height: 70px;
  }
  
  .divider-60 {
    height: 60px;
  }
  
  .divider-50 {
    height: 50px;
  }
  
  .divider-40 {
    height: 40px;
  }
  
  .divider-30 {
    height: 30px;
  }
  
  .divider-20 {
    height: 20px;
  }
  
  .divider-10 {
    height: 10px;
  }
  
  @media (max-width: 992px) {
    [class*="divider-"].divider-md-0 {
      height: 0;
    }
    [class*="divider-"].divider-md-10 {
      height: 10px;
    }
    [class*="divider-"].divider-md-20 {
      height: 20px;
    }
    [class*="divider-"].divider-md-30 {
      height: 30px;
    }
    [class*="divider-"].divider-md-40 {
      height: 40px;
    }
    [class*="divider-"].divider-md-50 {
      height: 50px;
    }
    [class*="divider-"].divider-md-60 {
      height: 60px;
    }
    [class*="divider-"].divider-md-70 {
      height: 70px;
    }
    [class*="divider-"].divider-md-80 {
      height: 80px;
    }
    [class*="divider-"].divider-md-90 {
      height: 90px;
    }
    [class*="divider-"].divider-md-100 {
      height: 100px;
    }
    [class*="divider-"].divider-md-110 {
      height: 110px;
    }
    [class*="divider-"].divider-md-120 {
      height: 120px;
    }
    [class*="divider-"].divider-md-130 {
      height: 130px;
    }
    [class*="divider-"].divider-md-140 {
      height: 140px;
    }
  }
  
  @media (max-width: 768px) {
    [class*="divider-"].divider-sm-0 {
      height: 0;
    }
    [class*="divider-"].divider-sm-10 {
      height: 10px;
    }
    [class*="divider-"].divider-sm-20 {
      height: 20px;
    }
    [class*="divider-"].divider-sm-30 {
      height: 30px;
    }
    [class*="divider-"].divider-sm-40 {
      height: 40px;
    }
    [class*="divider-"].divider-sm-50 {
      height: 50px;
    }
    [class*="divider-"].divider-sm-60 {
      height: 60px;
    }
    [class*="divider-"].divider-sm-70 {
      height: 70px;
    }
    [class*="divider-"].divider-sm-80 {
      height: 80px;
    }
    [class*="divider-"].divider-sm-90 {
      height: 90px;
    }
    [class*="divider-"].divider-sm-100 {
      height: 100px;
    }
    [class*="divider-"].divider-sm-110 {
      height: 110px;
    }
    [class*="divider-"].divider-sm-120 {
      height: 120px;
    }
    [class*="divider-"].divider-sm-130 {
      height: 130px;
    }
    [class*="divider-"].divider-sm-140 {
      height: 140px;
    }
  }
  
  @media (max-width: 480px) {
    [class*="divider-"].divider-xs-0 {
      height: 0;
    }
    [class*="divider-"].divider-xs-10 {
      height: 10px;
    }
    [class*="divider-"].divider-xs-20 {
      height: 20px;
    }
    [class*="divider-"].divider-xs-30 {
      height: 30px;
    }
    [class*="divider-"].divider-xs-40 {
      height: 40px;
    }
    [class*="divider-"].divider-xs-50 {
      height: 50px;
    }
    [class*="divider-"].divider-xs-60 {
      height: 60px;
    }
    [class*="divider-"].divider-xs-70 {
      height: 70px;
    }
    [class*="divider-"].divider-xs-80 {
      height: 80px;
    }
    [class*="divider-"].divider-xs-90 {
      height: 90px;
    }
    [class*="divider-"].divider-xs-100 {
      height: 100px;
    }
    [class*="divider-"].divider-xs-110 {
      height: 110px;
    }
    [class*="divider-"].divider-xs-120 {
      height: 120px;
    }
    [class*="divider-"].divider-xs-130 {
      height: 130px;
    }
    [class*="divider-"].divider-xs-140 {
      height: 140px;
    }
  }
  
  /*  Divider Space with Line */
  .divider-container {
    display: flex;
    position: relative;
  }
  
  .divider-line {
    display: inline-block;
    clear: both;
    margin: 90px auto 80px;
    width: calc(100% - 30px);
    border-top: 1px solid;
    border-top-color: #eee;
  }
  
  [class*="col-"] .divider-line {
    width: 100%;
  }
  
  /* Divider Space with Two Lines */
  .divider-two-lines {
    height: 4px;
    margin-bottom: 70px;
    border-bottom: 1px solid #eee;
    border-top: 1px solid #eee;
    display: block;
    position: relative;
    top: 60px;
    clear: both;
  }
  
  /*  [ 07 ] - Magnific Popup
  ============================= */
  /* Popup Fade Effect */
  .mfp-fade.mfp-bg {
    opacity: 0;
    transition: all 0.2s ease-out;
  }
  
  .mfp-fade.mfp-bg.mfp-ready {
    opacity: 0.8;
  }
  
  .mfp-fade.mfp-bg.mfp-removing {
    opacity: 0;
  }
  
  .mfp-fade.mfp-wrap .mfp-content {
    opacity: 0;
    transition: all 0.15s ease-out;
  }
  
  .mfp-fade.mfp-wrap.mfp-ready .mfp-content {
    opacity: 1;
  }
  
  .mfp-fade.mfp-wrap.mfp-removing .mfp-content {
    opacity: 0;
  }
  
  /*  [ 08 ] - Icons
  ==================== */
  .icon {
    position: relative;
    display: flex;
    align-items: flex-start;
    justify-content: center;
    /* Sizes */
    /* Colors */
    /* Spaced */
  }
  
  .icon i,
  .icon img {
    display: block;
  }
  
  .icon img {
    max-width: 100%;
    max-height: 100%;
  }
  
  .icon.x5 {
    width: 70px;
    height: 70px;
    font-size: 70px;
  }
  
  .icon.white i {
    color: #fff;
  }
  
  .icon.spaced {
    align-items: center;
    padding: 10px;
    border-radius: 4px;
    /* Sizes */
    /* Rounded */
    /* Colors */
  }
  
  .icon.spaced.x5 {
    width: 70px;
    height: 70px;
    font-size: 28px;
  }
  
  .icon.spaced.rounded {
    border-radius: 100%;
  }
  
  .icon.spaced.colorful-bg {
    background-color: #863260;
  }
  
  /*  [ 09 ] - Buttons
  ====================== */
  .btn {
    overflow: hidden;
    position: relative;
    z-index: 10;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    padding: 17px 35px;
    font-size: 14px;
    line-height: 1.75;
    border-radius: 3px;
    font-weight: 700;
    letter-spacing: 0.3px;
    text-align: center;
    border: 2px solid;
    box-shadow: 0px 8px 15px rgba(0, 0, 0, 0.1);
    font-family: "Titillium Web", "Almarai", sans-serif;
    transition: color 0.2s, background 0.2s, border-color 0.2s, box-shadow 0.2s, transform 0.15s;
    /* Sizes */
    /* Position */
    /* Styles */
  }
  
  .btn button.btn {
    box-shadow: none;
  }
  
  .btn.rounded {
    border-radius: 999px;
  }
  
  .btn.with-icon.medium i {
    margin-left: 8px;
    font-size: 16px;
  }
  
  .btn.with-icon.small i {
    margin-left: 6px;
    font-size: 16px;
  }
  
  .btn.x-small {
    padding: 5px 16px;
    font-size: 12px;
  }
  
  .btn.small {
    padding: 4px 24px;
    font-size: 16px;
  }
  
  .btn.medium {
    padding: 11px 30px;
    font-size: 16px;
  }
  
  @media (max-width: 480px) {
    .btn.medium {
      padding: 8px 20px;
      font-size: 12px;
    }
  }
  
  .btn.large {
    padding: 7px 35px;
    font-size: 18px;
    border-width: 3px;
  }
  
  @media (max-width: 480px) {
    .btn.large {
      padding: 6px 32px;
      font-size: 16px;
    }
  }
  
  .btn.x-large {
    padding: 11px 44px;
    font-size: 24px;
    border-width: 3px;
  }
  
  @media (max-width: 480px) {
    .btn.x-large {
      padding: 8px 40px;
      font-size: 21px;
    }
  }
  
  .btn.xx-large {
    padding: 18px 57px;
    font-size: 20px;
    border-width: 3px;
  }
  
  .btn.position-right {
    margin-left: auto;
  }
  
  .btn.colorful, .btn.hover-colorful:hover {
    background-color: #863260;
    color: #fff;
    text-shadow: 1px 1px 1px rgba(0, 0, 0, 0.1);
    border-color: #863260;
  }
  
  .btn.colorful-2, .btn.hover-colorful-2:hover {
    background-color: #04b753;
    color: #fff;
    text-shadow: 1px 1px 1px rgba(0, 0, 0, 0.1);
    border-color: #04b753;
  }
  
  .btn.white, .btn.hover-white:hover {
    background: #fff;
    color: #222;
    text-shadow: none;
    border-color: #fff;
  }
  
  .btn.grey, .btn.hover-grey:hover {
    background: #eee;
    color: #222;
    text-shadow: none;
    border-color: #eee;
  }
  
  .btn.dark, .btn.hover-dark:hover {
    background: #222;
    color: #fff;
    text-shadow: none;
    border-color: #222;
  }
  
  .btn.colorful-darken, .btn.hover-colorful-darken:hover {
    background-color: #04b753;
    color: #fff;
    text-shadow: 1px 1px 1px rgba(0, 0, 0, 0.1);
    border-color: #04b753;
  }
  
  .btn.gradient-1 {
    border: none;
    color: #fff;
    background-color: #863260;
    background-image: -webkit-linear-gradient(235deg, #863260 0%, #04b753 100%);
    background-image: -moz-linear-gradient(235deg, #863260 0%, #04b753 100%);
    background-image: -o-linear-gradient(235deg, #863260 0%, #04b753 100%);
    background-image: linear-gradient(235deg, #863260 0%, #04b753 100%);
  }
  
  .btn.gradient-1::before {
    background-color: #04b753;
    background-image: -webkit-linear-gradient(190deg, #04b753 0%, #863260 100%);
    background-image: -moz-linear-gradient(190deg, #04b753 0%, #863260 100%);
    background-image: -o-linear-gradient(190deg, #04b753 0%, #863260 100%);
    background-image: linear-gradient(190deg, #04b753 0%, #863260 100%);
  }
  
  .btn.gradient-2 {
    border: none;
    color: #fff;
    background-color: #ef8405;
    background-image: -webkit-linear-gradient(90deg, #ef8405 0%, #ff991f 100%);
    background-image: -moz-linear-gradient(90deg, #ef8405 0%, #ff991f 100%);
    background-image: -o-linear-gradient(90deg, #ef8405 0%, #ff991f 100%);
    background-image: linear-gradient(90deg, #ef8405 0%, #ff991f 100%);
    box-shadow: 0 7px 32px 0 rgba(166, 165, 165, 0.34);
  }
  
  .btn.gradient-2::before {
    background-color: #ff991f;
    background-image: -webkit-linear-gradient(0deg, #ff991f 0%, #ef8405 100%);
    background-image: -moz-linear-gradient(0deg, #ff991f 0%, #ef8405 100%);
    background-image: -o-linear-gradient(0deg, #ff991f 0%, #ef8405 100%);
    background-image: linear-gradient(0deg, #ff991f 0%, #ef8405 100%);
  }
  
  .btn.gradient-2:hover {
    box-shadow: 0 7px 10px 0 rgba(166, 165, 165, 0.6);
  }
  
  .btn[class*="gradient-"]:hover::before {
    opacity: 1;
  }
  
  .btn[class*="gradient-"]::before {
    content: "";
    position: absolute;
    right: 0;
    top: 0;
    z-index: -1;
    width: 100%;
    height: 100%;
    opacity: 0;
    transition: all 0.2s;
  }
  
  .btn.move-top {
    transform: translateY(0);
  }
  
  .btn.move-top:hover {
    transform: translateY(-3px);
  }
  
  @media (max-width: 480px) {
    .btn.xx-large {
      padding: 6px 45px;
      font-size: 20px;
    }
  }
  
  /*  [ 10 ] - Social Icons
  =========================== */
  /* Social Icons */
  .social-icons {
    position: relative;
    display: flex;
    list-style: none;
    margin-bottom: -5px;
    font-size: 0;
    /* Sizes */
    /* Animated */
    /* Colors */
  }
  
  .social-icons li {
    display: inline-block;
    margin-bottom: 5px;
  }
  
  .social-icons li + li {
    margin-left: 10px;
  }
  
  .social-icons li a {
    display: block;
    position: relative;
    overflow: hidden;
    text-align: center;
    background: transparent;
    border-radius: 3px;
    transition: all 0.3s ease;
  }
  
  .social-icons li a i {
    position: relative;
    top: 50%;
    display: block;
    margin: 0 auto;
    transform: translateY(-50%);
    transition: all 0.25s ease;
  }
  
  .social-icons li a i:first-child, .social-icons li a i:last-child {
    display: flex;
    align-items: center;
    justify-content: center;
  }
  
  .social-icons li a i:last-child {
    opacity: 0;
  }
  
  .social-icons.x3 li a {
    width: 30px;
    height: 30px;
    font-size: 12px;
  }
  
  .social-icons.x3 li i:first-child, .social-icons.x3 li i:last-child {
    width: 16px;
    height: 16px;
  }
  
  .social-icons.x4 li a {
    width: 40px;
    height: 40px;
    font-size: 16px;
  }
  
  .social-icons.x4 li i:first-child, .social-icons.x4 li i:last-child {
    width: 26px;
    height: 26px;
  }
  
  .social-icons.icon-only li + li {
    margin-left: 2px;
  }
  
  .social-icons.icon-only.x4 li a {
    width: 30px;
    height: 30px;
  }
  
  .social-icons.rounded a {
    border-radius: 999px;
  }
  
  .social-icons.animated li a:hover i:last-child {
    opacity: 1;
  }
  
  .social-icons.animated li a:hover i:first-child {
    opacity: 0;
  }
  
  .social-icons.animated.x4 li a:hover i:first-child {
    margin-top: -26px;
  }
  
  .social-icons.animated.x5 li a:hover i:first-child {
    margin-top: -28px;
  }
  
  .social-icons.white li a,
  .social-icons.grey li a,
  .social-icons.hover-colorful li a:hover {
    background: transparent;
  }
  
  .social-icons.grey li a i:first-child {
    color: #888;
  }
  
  .social-icons.white li a i:first-child {
    color: #fff;
  }
  
  .social-icons.hover-colorful li a i:last-child,
  .social-icons.hover-colorful:not(.animated) li a:hover i:first-child {
    color: #863260;
  }
  
  .social-icons.hover-white li a i:last-child,
  .social-icons.hover-white:not(.animated) li a:hover i:first-child {
    color: #fff;
  }
  
  .social-icons.white-bg a {
    background-color: #fff;
  }
  
  .social-icons.white-bg a i:first-child {
    color: #222;
  }
  
  .social-icons.grey-bg a {
    background-color: #eee;
  }
  
  .social-icons.grey-bg a i:first-child {
    color: #222;
  }
  
  .social-icons.white-transparent-bg a {
    background-color: #fff;
    border: 1px solid #eee;
  }
  
  .social-icons.white-transparent-bg a i:first-child {
    color: #222;
  }
  
  .social-icons.hover-white-bg a:hover {
    background-color: #fff !important;
  }
  
  .social-icons.hover-white-bg a:hover i:first-child {
    color: #000;
  }
  
  .social-icons.hover-dark-bg a:hover {
    background-color: #222;
  }
  
  .social-icons.hover-dark-bg a:hover i:first-child {
    color: #fff;
  }
  
  .social-icons.hover-colorful-bg a:hover {
    background-color: #863260;
    border-color: #863260;
  }
  
  .social-icons.hover-colorful-bg a:hover i:first-child {
    color: #fff;
  }
  
  .social-icons.hover-colorful-bg a:hover i:last-child {
    color: #fff;
  }
  
  .social-icons.colorful-bg li a i:first-child {
    color: #fff;
  }
  
  .social-icons.colorful-bg li a.si-facebook {
    background-color: #3b5999;
  }
  
  .social-icons.colorful-bg li a.si-twitter {
    background-color: #55acee;
  }
  
  .social-icons.colorful-bg li a.si-instagramorange {
    background-color: #f77737;
  }
  
  .social-icons.colorful-bg li a.si-linkedin {
    background-color: #007bb5;
  }
  
  /*  [ 11 ] - Scroll Top Icon
  ============================== */
  .scroll-top-icon {
    width: 30px;
    height: 30px;
    line-height: 29px;
    text-align: center;
    color: #fff;
    font-size: 11px;
    border-radius: 2px;
    background-color: #333;
    position: fixed;
    bottom: -50px;
    right: 40px;
    z-index: 15;
  }
  
  .scroll-top-icon.show {
    bottom: 40px;
  }
  
  .scroll-top-icon:hover {
    background-color: #3a3a3a;
    color: #fff;
  }
  
  .scroll-top-icon:focus {
    color: #fff;
  }
  
  @media (max-width: 768px) {
    .scroll-top-icon {
      display: none !important;
    }
  }
  
  /*  [ 11 ] - Page Title
  ========================= */
  #page-title {
    position: relative;
    background-color: #fafafa;
  }
  
  #page-title #page-title-wrap {
    padding: 60px 0;
    position: relative;
    z-index: 1;
  }
  
  #page-title .container,
  #page-title .row,
  #page-title [class*="col-"] {
    height: 100%;
  }
  
  #page-title > h2 {
    margin-bottom: 6px;
  }
  
  #page-title h4 {
    margin: 15px 0 0;
  }
  
  #page-title > h5 {
    font-weight: 400;
    opacity: 0.7;
    margin-bottom: 0;
    font-family: "Open Sans", "Almarai", sans-serif;
    font-size: 18px;
    display: none;
  }
  
  /* Breadcrumb */
  .breadcrumb {
    list-style: none;
    display: flex;
    padding: 30px 0;
    border-top: 1px solid rgba(255, 255, 255, 0.15);
    font-size: 20px;
    font-weight: 500;
  }
  
  .breadcrumb li {
    display: inline-block;
  }
  
  .breadcrumb li + li::before {
    content: "\f100";
    font-family: "Font Awesome 5 Free";
    padding: 0 10px;
    font-size: inherit;
    font-weight: 700;
    position: relative;
    top: 1px;
    color: #fff;
  }
  
  .breadcrumb li.active {
    color: #ff991f;
  }
  
  .breadcrumb a {
    color: #fff;
  }
  
  .breadcrumb a:hover {
    opacity: 0.8;
  }
  
  .breadcrumb i {
    margin-left: 5px;
    font-size: 16px;
  }
  
  /*  [ 12 ] - Tables
  ===================== */
  table {
    width: 100%;
  }
  
  table h5 {
    margin-bottom: 0;
  }
  
  table thead {
    border-bottom: 1px solid #ddd;
    font-size: 16px;
    color: #222;
  }
  
  table thead th {
    padding: 15px 20px;
    text-align: right;
    font-weight: 600;
  }
  
  table thead th + th {
    border-right: 1px solid #ddd;
  }
  
  table tbody {
    color: #222;
  }
  
  table tbody tr {
    background: #fff;
  }
  
  table tbody tr + tr {
    border-top: 1px solid #ddd;
  }
  
  table tbody td {
    padding: 25px 20px;
    font-size: 14px;
  }
  
  table tbody td + td {
    border-right: 1px solid #ddd;
  }
  
  table tbody td:first-child {
    padding-right: 0;
  }
  
  /*  [ 13 ] - Loading Progress
  =============================== */
  #loading-progress {
    position: fixed;
    z-index: 99999;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    display: flex;
    flex-direction: column;
    justify-content: center;
    text-align: center;
  }
  
  #loading-progress::before, #loading-progress::after {
    transition: 0.5s cubic-bezier(0.23, 1, 0.32, 1) 0.8s;
  }
  
  #loading-progress::before, #loading-progress::after {
    content: "";
    position: fixed;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
  }
  
  #loading-progress::before {
    background: #863260;
  }
  
  #loading-progress::after {
    background: #fff;
  }
  
  #loading-progress.hide-it {
    visibility: hidden;
    transition-delay: 1.5s;
  }
  
  #loading-progress.hide-it::before, #loading-progress.hide-it::after {
    transform: translateY(-100%);
  }
  
  #loading-progress.hide-it::before {
    transition-delay: 1.1s;
  }
  
  #loading-progress .logo {
    position: relative;
    z-index: 1;
    margin-bottom: 50px;
    transition: all 0.4s 0.3s;
  }
  
  #loading-progress .lp-content {
    position: relative;
    width: 100%;
    z-index: 1;
    transition: all 0.4s;
  }
  
  #loading-progress .lp-counter {
    margin-bottom: 9px;
    font-size: 14px;
    font-weight: 300;
    font-family: "Titillium Web", "Almarai", sans-serif;
    color: #888;
  }
  
  #loading-progress .lp-counter #lp-counter {
    display: inline-block;
    width: 33px;
    font-weight: 600;
    text-align: left;
    color: #222;
  }
  
  #loading-progress .lp-bar {
    position: relative;
    margin: auto;
    width: 150px;
    background: #eee;
  }
  
  #loading-progress .lp-bar #lp-bar {
    height: 2px;
    background: #863260;
    width: 0;
  }
  
  /* =====================================================================
   *   helpers-placeholders.scss
   * =================================================================== */
  /* BG Gradients */
  /* Opacity Values */
  .opacity-05 {
    opacity: 0.05;
  }
  
  .opacity-10 {
    opacity: 0.10;
  }
  
  .opacity-15 {
    opacity: 0.15;
  }
  
  .opacity-20 {
    opacity: 0.20;
  }
  
  .opacity-25 {
    opacity: 0.25;
  }
  
  .opacity-30 {
    opacity: 0.30;
  }
  
  .opacity-35 {
    opacity: 0.35;
  }
  
  .opacity-40 {
    opacity: 0.40;
  }
  
  .opacity-45 {
    opacity: 0.45;
  }
  
  .opacity-50 {
    opacity: 0.50;
  }
  
  .opacity-55 {
    opacity: 0.55;
  }
  
  .opacity-60 {
    opacity: 0.60;
  }
  
  .opacity-65 {
    opacity: 0.65;
  }
  
  .opacity-70 {
    opacity: 0.70;
  }
  
  .opacity-75 {
    opacity: 0.75;
  }
  
  .opacity-80 {
    opacity: 0.80;
  }
  
  .opacity-85 {
    opacity: 0.85;
  }
  
  .opacity-90 {
    opacity: 0.90;
  }
  
  .opacity-95 {
    opacity: 0.95;
  }
  
  .opacity-100 {
    opacity: 1;
  }
  
  /* =====================================================================
   *   helpers-placeholders.scss
   * =================================================================== */
  /* BG Gradients */
  /* Opacity Values */
  .opacity-05 {
    opacity: 0.05;
  }
  
  .opacity-10 {
    opacity: 0.10;
  }
  
  .opacity-15 {
    opacity: 0.15;
  }
  
  .opacity-20 {
    opacity: 0.20;
  }
  
  .opacity-25 {
    opacity: 0.25;
  }
  
  .opacity-30 {
    opacity: 0.30;
  }
  
  .opacity-35 {
    opacity: 0.35;
  }
  
  .opacity-40 {
    opacity: 0.40;
  }
  
  .opacity-45 {
    opacity: 0.45;
  }
  
  .opacity-50 {
    opacity: 0.50;
  }
  
  .opacity-55 {
    opacity: 0.55;
  }
  
  .opacity-60 {
    opacity: 0.60;
  }
  
  .opacity-65 {
    opacity: 0.65;
  }
  
  .opacity-70 {
    opacity: 0.70;
  }
  
  .opacity-75 {
    opacity: 0.75;
  }
  
  .opacity-80 {
    opacity: 0.80;
  }
  
  .opacity-85 {
    opacity: 0.85;
  }
  
  .opacity-90 {
    opacity: 0.90;
  }
  
  .opacity-95 {
    opacity: 0.95;
  }
  
  .opacity-100 {
    opacity: 1;
  }
  
  /**
    * -------------------------------------------------------------------------------
    * ( 03 ) - Header
    * -------------------------------------------------------------------------------
    *
    *  This part contains logo, main menu and header bars.
    *
    * ------
    *
    *  Header is divided to the following sections:
    *
    *    |
    *    |-->  [ 01 ] - Logo
    *    |-->  [ 02 ] - Menu Main
    *    |-->  [ 03 ] - Header Bars
    *
    */
  #header {
    position: relative;
    top: 0;
    left: 0;
    width: 100%;
  }
  
  /*  [ 01 ] - Logo
  =================== */
  .logo {
    display: inline-block;
  }
  
  .logo img {
    max-height: 100%;
  }
  
  .logo h3,
  .logo h3 + span {
    display: none;
  }
  
  .logo.text-white h3,
  .logo.text-white span {
    color: #fff;
  }
  
  .logo.logo-text img {
    display: none;
  }
  
  .logo.logo-text h3,
  .logo.logo-text h3 + span {
    display: block;
  }
  
  .logo.logo-text h3 {
    position: relative;
    margin-bottom: -1px;
    font-size: 20px;
    font-weight: 600;
    color: #fff;
  }
  
  .logo.logo-text h3 + span {
    font-size: 11px;
    color: #888;
  }
  
  .logo-header {
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: center;
    height: 43px;
    margin-right: 20px;
    transition: color 0.2996s, top 0.3s;
  }
  
  @media (max-width: 480px) {
    .logo-header {
      height: 35px;
    }
  }
  
  .logo-header.logo-text {
    text-align: left;
  }
  
  /*  [ 03 ] - Header Bars
  ========================== */
  /* Header Bar */
  .header-bar,
  .header-bar-wrap {
    background: transparent;
    transition: height 0.3s, background 0.3s;
  }
  
  .header-bar-wrap {
    position: relative;
    left: 0;
    top: 0;
    width: 100%;
  }
  
  .header-bar .container,
  .header-bar .row,
  .header-bar [class*="col-"] {
    height: 100%;
  }
  
  .header-bar .col-md-12 {
    width: 100%;
  }
  
  .header-bar .hb-content {
    display: flex;
    align-items: center;
    height: 100%;
    transition: all 0.2s;
  }
  
  /* Header Bar 1 */
  #header-bar-1 {
    position: absolute;
    left: 0;
    top: 0;
    z-index: 10;
    width: 100%;
  }
  
  #header-bar-1 .hb-content {
    margin-left: auto;
  }
  
  #header-bar-1,
  #header-bar-1 .header-bar-wrap {
    height: 110px;
  }
  
  #header-bar-1 .header-bar-wrap {
    z-index: 1;
  }
  
  #header-bar-1.is-sticky,
  #header-bar-1.is-sticky .header-bar-wrap {
    height: 90px;
  }
  
  @media (max-width: 480px) {
    #header-bar-1.is-sticky,
    #header-bar-1.is-sticky .header-bar-wrap {
      height: auto;
    }
  }
  
  #header-bar-1.is-sticky .header-bar-wrap {
    background-color: #222;
  }
  
  @media (max-width: 480px) {
    #header-bar-1.is-sticky .hb-content {
      padding: 20px 0;
    }
  }
  
  @media (max-width: 480px) {
    #header-bar-1,
    #header-bar-1 .header-bar-wrap {
      height: auto;
    }
    #header-bar-1 .hb-content {
      padding: 30px 0;
    }
    #header-bar-1 .logo-header {
      margin: 0;
    }
  }
  
  /* =====================================================================
   *   helpers-placeholders.scss
   * =================================================================== */
  /* BG Gradients */
  /* Opacity Values */
  .opacity-05 {
    opacity: 0.05;
  }
  
  .opacity-10 {
    opacity: 0.10;
  }
  
  .opacity-15 {
    opacity: 0.15;
  }
  
  .opacity-20 {
    opacity: 0.20;
  }
  
  .opacity-25 {
    opacity: 0.25;
  }
  
  .opacity-30 {
    opacity: 0.30;
  }
  
  .opacity-35 {
    opacity: 0.35;
  }
  
  .opacity-40 {
    opacity: 0.40;
  }
  
  .opacity-45 {
    opacity: 0.45;
  }
  
  .opacity-50 {
    opacity: 0.50;
  }
  
  .opacity-55 {
    opacity: 0.55;
  }
  
  .opacity-60 {
    opacity: 0.60;
  }
  
  .opacity-65 {
    opacity: 0.65;
  }
  
  .opacity-70 {
    opacity: 0.70;
  }
  
  .opacity-75 {
    opacity: 0.75;
  }
  
  .opacity-80 {
    opacity: 0.80;
  }
  
  .opacity-85 {
    opacity: 0.85;
  }
  
  .opacity-90 {
    opacity: 0.90;
  }
  
  .opacity-95 {
    opacity: 0.95;
  }
  
  .opacity-100 {
    opacity: 1;
  }
  
  /**
    * -------------------------------------------------------------------------------
    * ( 04 ) - Banner
    * -------------------------------------------------------------------------------
    *
    *  This part contains all banner related contents like banner slider, bannar
    *  parallax
    *
    * ------
    *
    *  Banner is divided to the following sections:
    *
    *    |
    *    |-->  [ 01 ] - Banner Parallax
    *    |-->  [ 02 ] - Banner Slider
    *
    */
  #banner {
    position: relative;
    background-color: #fff;
  }
  
  #banner.fullscreen .banner-parallax,
  #banner.fullscreen .banner-parallax .row > [class*="col-"] {
    min-height: auto;
  }
  
  /*  [ 01 ] - Banner Parallax
  ============================== */
  .banner-parallax {
    position: relative;
    background-color: #fff;
    background-position: top center;
    background-size: cover;
    background-repeat: no-repeat;
  }
  
  .banner-parallax.blured-bg > .bg-element {
    -webkit-filter: blur(5px);
    filter: blur(5px);
  }
  
  .banner-parallax > .bg-element {
    position: absolute;
    width: 100%;
    height: 100%;
    background: no-repeat top center / cover;
    opacity: 0;
    transition: opacity 1s, filter 1s;
  }
  
  @media (max-width: 992px) {
    .banner-parallax > .bg-element {
      background-position: initial !important;
    }
  }
  
  .banner-parallax > .bg-element.appeared {
    opacity: 1;
    -webkit-filter: blur(0);
    filter: blur(0);
  }
  
  .banner-parallax > .bg-element + img {
    display: none;
  }
  
  .banner-parallax > .overlay-pattern {
    opacity: 0;
    transition: opacity 0.5s;
  }
  
  .banner-parallax > .overlay-pattern.appeared {
    opacity: 1;
  }
  
  .banner-parallax,
  .banner-parallax .row > [class*="col-"] {
    min-height: 800px;
  }
  
  @media (max-width: 1400px) {
    .banner-parallax,
    .banner-parallax .row > [class*="col-"] {
      height: auto !important;
      min-height: auto !important;
    }
  }
  
  .banner-parallax .row {
    display: flex;
    align-items: center;
  }
  
  .banner-parallax .row > [class*="col-"] {
    display: flex;
    align-items: center;
  }
  
  .banner-parallax .container,
  .banner-parallax .row,
  .banner-parallax .row > [class*="col-"] {
    height: 100%;
    z-index: 1;
  }
  
  .overlay-colored,
  .overlay-pattern {
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
  }
  
  .overlay-colored {
    z-index: 1;
  }
  
  /* .overlay-pattern {
    z-index: 2;
    background-image: url("../images/general-elements/overlay-pattern.png");
    background-repeat: repeat;
    background-position: top left;
  } */
  
  @media (min-width: 992px) {
    .fullscreen .banner-parallax,
    .fullscreen .banner-slider {
      height: 100% !important;
    }
  }
  
  .video-background {
    position: absolute;
    width: 100%;
    height: 100%;
  }
  
  .owl-carousel .owl-item {
    -webkit-backface-visibility: initial;
    backface-visibility: initial;
  }
  
  /* Slide Content */
  .slide-content {
    height: 100%;
    width: 100%;
    position: relative;
    top: 0;
    z-index: 2;
  }
  
  .slide-content img {
    display: inline-block !important;
  }
  
  .slide-content h4 {
    margin-bottom: 20px;
    font-weight: 500;
    opacity: 0.8;
  }
  
  .slide-content .subheading {
    display: block;
    margin-bottom: 12px;
    text-transform: uppercase;
    letter-spacing: 2px;
  }
  
  .slide-content h1 {
    position: relative;
    font-size: 48px;
    line-height: 1.2;
  }
  
  @media (max-width: 1400px) {
    .slide-content h1 {
      font-size: 48px;
    }
  }
  
  @media (max-width: 480px) {
    .slide-content h1 {
      font-size: 40px;
    }
  }
  
  .slide-content p {
    font-size: 20px;
  }
  
  @media (max-width: 480px) {
    .slide-content p {
      font-size: 18px;
    }
  }
  
  .slide-content .text-center h1,
  .slide-content .text-center h4 {
    margin-left: auto;
    margin-right: auto;
  }
  
  .slide-content .text-center h1 {
    width: 85%;
  }
  
  .slide-content .text-right h1 {
    margin-left: auto;
  }
  
  .slide-content .text-right .description {
    margin-left: auto;
  }
  
  @media (max-width: 992px) {
    .slide-content [class*="text-center"] h1,
    .slide-content [class*="text-center"] h4 {
      width: 100%;
    }
    .slide-content [class*="text-center"] .description {
      margin-left: auto;
      margin-right: auto;
      width: 80%;
    }
    .slide-content .col-md-10 .text-center h1,
    .slide-content .col-md-10 .text-center .description {
      width: 100%;
    }
  }
  
  @media (max-width: 480px) {
    .slide-content h1,
    .slide-content p,
    .slide-content .description {
      width: 100%;
    }
    .slide-content [class*="text-center"] .description {
      margin-top: 0;
      width: 100%;
    }
  }
  
  .btn-row {
    display: inline-flex;
    align-items: center;
    width: 100%;
  }
  
  .btn-row.style-2 {
    flex-direction: column;
    justify-content: flex-start;
  }
  
  @media (max-width: 992px) {
    .btn-row.style-2 {
      flex-direction: row;
    }
  }
  
  .btn-row small {
    margin-left: 20px;
    opacity: 0.4;
    transition: all 0.2s;
  }
  
  .text-white .btn-row small {
    color: #fff;
  }
  
  .style-2.btn-row small {
    margin: 20px 0 0;
    text-align: center;
  }
  
  @media (max-width: 992px) {
    .style-2.btn-row small {
      margin: 0 0 0 20px;
      text-align: left;
    }
  }
  
  .btn-row .btn:hover + small {
    opacity: 0.6;
  }
  
  /* Banner Center Box */
  .banner-center-box {
    padding: 160px 0 160px;
    max-height: 100%;
    max-width: 100%;
    width: 100%;
    perspective: 1000px;
    backface-visibility: hidden;
  }
  
  @media (max-width: 480px) {
    .banner-center-box {
      padding: 130px 0;
    }
  }
  
  .banner-center-box > * {
    position: relative;
  }
  
  .banner-center-box.text-center {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  
  .banner-center-box.text-white h1,
  .banner-center-box.text-white .subheading,
  .banner-center-box.text-white .description,
  .banner-center-box.text-white .btn-row small {
    color: #fff;
  }
  
  .banner-center-box.text-white .description {
    font-weight: 300;
    opacity: 0.65;
  }
  
  .banner-center-box.bottom-position {
    margin-top: auto;
    padding-bottom: 0;
  }
  
  .banner-center-box h1:last-child,
  .banner-center-box h2:last-child,
  .banner-center-box h3:last-child,
  .banner-center-box h4:last-child,
  .banner-center-box h5:last-child,
  .banner-center-box h6:last-child {
    padding-bottom: 0;
    margin-bottom: 0;
  }
  
  .banner-center-box img {
    max-height: 100%;
    max-width: 100%;
  }
  
  @media (max-width: 992px) {
    /* Column With Helper Classes Optimization */
    [class*="col-"][class*="pl-"] {
      padding-left: 15px;
    }
    [class*="col-"][class*="pr-"] {
      padding-right: 15px;
    }
    /* Banner Parallax */
    .banner-parallax,
    .banner-parallax .row > [class*="col-"] {
      height: auto !important;
      min-height: auto !important;
    }
    .banner-parallax .row {
      flex-direction: column;
    }
    .banner-parallax .row > [class*="col-"] {
      align-self: stretch;
      flex-grow: 1;
    }
    .banner-parallax [class*="col-"]:not(:first-child) .banner-center-box {
      padding-top: 0 !important;
      margin-top: -80px;
    }
    .banner-center-box {
      display: block;
      top: 0;
      width: 100%;
      transform: translateY(0);
    }
    /* Slider */
    .fullscreen {
      height: auto !important;
    }
    .banner-slider {
      min-height: auto !important;
    }
  }
  
  /* Slick Default Settings */
  .slick-slider .slick-list {
    margin-left: -15px;
    margin-right: -15px;
  }
  
  .slick-slider .slick-track {
    display: flex;
  }
  
  .slick-slider .slick-slide {
    overflow: hidden;
    position: relative;
    margin-left: 15px;
    margin-right: 15px;
    outline: none;
    float: left;
  }
  
  /* Slick Dots */
  .slick-dotted .slick-dots {
    list-style: none;
    position: relative;
    z-index: 1;
    display: flex !important;
    align-items: center;
    justify-content: center;
    margin-top: 40px;
    font-size: 0;
  }
  
  .slick-dotted .slick-dots li {
    margin: 0 5px;
  }
  
  .slick-dotted .slick-dots li button {
    display: flex;
    align-items: center;
    justify-content: center;
    border: none;
    outline: none;
    width: 12px;
    height: 12px;
    border-radius: 999px;
    transition: all 0.2s;
    background-color: #aaa;
  }
  
  .slick-dotted .slick-dots li.slick-active button {
    background-color: #863260;
  }
  
  /* Slick Arrows */
  .slick-arrows {
    display: flex;
    justify-content: center;
    margin-bottom: 30px;
    font-size: 30px;
  }
  
  .slick-arrows a {
    margin: 0 5px;
  }
  
  .slick-arrows a:hover {
    color: #000;
  }
  
  .slick-arrows a.slick-disabled {
    opacity: 0.5;
  }
  
  .slick-arrows a.slick-disabled:hover {
    color: #863260;
  }
  
  .slick-arrows i {
    display: block;
  }
  
  /* Slider Banner */
  .slider-banner {
    position: relative;
  }
  
  .slider-banner .slide {
    width: 100%;
  }
  
  @media (max-width: 1400px) {
    .slider-banner a img {
      max-height: 400px;
    }
  }
  
  .slider-banner .slick-slide {
    display: flex;
    justify-content: flex-end;
  }
  
  .slider-banner .slick-arrows {
    justify-content: space-between;
    font-size: 25px;
    margin: 0;
    width: 100%;
  }
  
  .slider-banner .slick-arrows a {
    position: absolute;
    top: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 0;
    width: 70px;
    height: 70px;
    opacity: 0.6;
    border-radius: 999px;
    transform: translateY(-50%);
    background-color: rgba(0, 0, 0, 0.3);
    color: #fff;
  }
  
  .slider-banner .slick-arrows a.slick-arrow i {
    position: relative;
    top: -2px;
    transform: rotate(90deg);
  }
  
  .slider-banner .slick-arrows a.slick-next {
    right: 40px;
  }
  
  .slider-banner .slick-arrows a.slick-next i {
    right: -2px;
  }
  
  .slider-banner .slick-arrows a.slick-next:hover {
    margin-right: -3px;
  }
  
  .slider-banner .slick-arrows a.slick-prev {
    left: 40px;
  }
  
  .slider-banner .slick-arrows a.slick-prev i {
    left: -2px;
  }
  
  .slider-banner .slick-arrows a.slick-prev:hover {
    margin-left: -3px;
  }
  
  .slider-banner .slick-arrows a:hover {
    background-color: #863260;
  }
  
  .slider-banner .slick-arrows a.slick-disabled {
    opacity: 0.5;
  }
  
  .slider-banner .slick-arrows a.slick-disabled:hover {
    background-color: #863260;
    color: #fff;
  }
  
  .slider-banner .slick-dots {
    position: absolute;
    bottom: 50px;
    margin-top: 0;
    width: 100%;
  }
  
  .slider-banner:hover .slick-arrow {
    opacity: 1;
  }
  
  /* Box Form */
  .box-form {
    position: relative;
    padding: 50px;
    border-radius: 10px;
    background-color: #fff;
  }
  
  @media (max-width: 480px) {
    .box-form {
      padding: 30px;
    }
  }
  
  .box-form .box-title {
    position: relative;
    padding-bottom: 30px;
  }
  
  .box-form .box-title h3 {
    margin-bottom: 5px;
  }
  
  .box-form .box-title p {
    margin-bottom: 0;
    font-size: 16px;
  }
  
  .box-form .box-title .icon {
    display: inline-flex;
    align-items: center;
    justify-content: center;
    margin-bottom: 15px;
    width: 70px;
    height: 70px;
    border-radius: 999px;
    font-size: 30px;
    background-color: rgba(134, 50, 96, 0.15);
    color: #222;
  }
  
  /* CTA Subscribe Notifications */
  .cs-notifications {
    display: none;
    margin-bottom: 20px;
    text-align: left;
  }
  
  .cs-notifications a {
    text-decoration: underline;
    color: #fff;
  }
  
  .cs-notifications a:hover {
    color: #fff;
    text-decoration: none;
  }
  
  select option[disabled] {
    color: #ddd;
  }
  
  .cs-notifications-content {
    padding: 15px 20px 15px 48px;
    border-radius: 5px;
    display: block;
    font-size: 14px;
    line-height: 20px;
    color: #fff;
    font-weight: 700;
    background-color: #ff3333;
    position: relative;
  }
  
  .cs-notifications-content.sent {
    background-color: #3aae39;
  }
  
  .cs-error-icon,
  .cs-success-icon {
    color: #fff;
    font-size: 12px !important;
    width: 24px;
    height: 24px;
    line-height: 24px !important;
    background-color: rgba(0, 0, 0, 0.2);
    left: 15px;
    position: absolute;
    top: 13px;
    border-radius: 50%;
    text-align: center;
  }
  
  /* animate.css used code */
  .animated {
    -webkit-animation-duration: 1s;
    animation-duration: 1s;
    -webkit-animation-fill-mode: both;
    animation-fill-mode: both;
  }
  
  .shake {
    -webkit-animation-name: shake;
    animation-name: shake;
  }
  
  @-webkit-keyframes shake {
    from,
    to {
      -webkit-transform: translate3d(0, 0, 0);
      transform: translate3d(0, 0, 0);
    }
    10%,
    30%,
    50%,
    70%,
    90% {
      -webkit-transform: translate3d(-10px, 0, 0);
      transform: translate3d(-10px, 0, 0);
    }
    20%,
    40%,
    60%,
    80% {
      -webkit-transform: translate3d(10px, 0, 0);
      transform: translate3d(10px, 0, 0);
    }
  }
  
  @keyframes shake {
    from,
    to {
      -webkit-transform: translate3d(0, 0, 0);
      transform: translate3d(0, 0, 0);
    }
    10%,
    30%,
    50%,
    70%,
    90% {
      -webkit-transform: translate3d(-10px, 0, 0);
      transform: translate3d(-10px, 0, 0);
    }
    20%,
    40%,
    60%,
    80% {
      -webkit-transform: translate3d(10px, 0, 0);
      transform: translate3d(10px, 0, 0);
    }
  }
  
  @-webkit-keyframes bounce {
    from,
    20%,
    53%,
    80%,
    to {
      -webkit-animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
      animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
      -webkit-transform: translate3d(0, 0, 0);
      transform: translate3d(0, 0, 0);
    }
    40%,
    43% {
      -webkit-animation-timing-function: cubic-bezier(0.755, 0.05, 0.855, 0.06);
      animation-timing-function: cubic-bezier(0.755, 0.05, 0.855, 0.06);
      -webkit-transform: translate3d(0, -30px, 0);
      transform: translate3d(0, -30px, 0);
    }
    70% {
      -webkit-animation-timing-function: cubic-bezier(0.755, 0.05, 0.855, 0.06);
      animation-timing-function: cubic-bezier(0.755, 0.05, 0.855, 0.06);
      -webkit-transform: translate3d(0, -15px, 0);
      transform: translate3d(0, -15px, 0);
    }
    90% {
      -webkit-transform: translate3d(0, -4px, 0);
      transform: translate3d(0, -4px, 0);
    }
  }
  
  @keyframes bounce {
    from,
    20%,
    53%,
    80%,
    to {
      -webkit-animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
      animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
      -webkit-transform: translate3d(0, 0, 0);
      transform: translate3d(0, 0, 0);
    }
    40%,
    43% {
      -webkit-animation-timing-function: cubic-bezier(0.755, 0.05, 0.855, 0.06);
      animation-timing-function: cubic-bezier(0.755, 0.05, 0.855, 0.06);
      -webkit-transform: translate3d(0, -30px, 0);
      transform: translate3d(0, -30px, 0);
    }
    70% {
      -webkit-animation-timing-function: cubic-bezier(0.755, 0.05, 0.855, 0.06);
      animation-timing-function: cubic-bezier(0.755, 0.05, 0.855, 0.06);
      -webkit-transform: translate3d(0, -15px, 0);
      transform: translate3d(0, -15px, 0);
    }
    90% {
      -webkit-transform: translate3d(0, -4px, 0);
      transform: translate3d(0, -4px, 0);
    }
  }
  
  .bounce {
    -webkit-animation-name: bounce;
    animation-name: bounce;
    -webkit-transform-origin: center bottom;
    transform-origin: center bottom;
  }
  
  @media (prefers-reduced-motion) {
    .animated {
      -webkit-animation: unset !important;
      animation: unset !important;
      -webkit-transition: none !important;
      transition: none !important;
    }
  }
  
  /* Form cta Subscribe 1 */
  #form-cta-subscribe-1 .form-group {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
  }
  
  /* Center Horizontal */
  .center-horizontal {
    position: relative;
    display: inline-block;
    right: -50%;
    transform: translateX(-50%);
  }
  
  @media (max-width: 480px) {
    .xs-center-horizontal {
      position: relative;
      display: inline-block;
      left: -50%;
      transform: translateX(50%);
    }
  }
  
  @media (max-width: 992px) {
    .md-no-center-horizontal {
      right: 0;
      transform: translateX(0);
    }
  }
  
  /*  [ 10 ] - Page Thank You
  ============================= */
  .page-thank-you #banner .description {
    font-size: 20px;
    opacity: 0.7;
  }
  
  .page-thank-you .banner-center-box {
    padding: 120px 0;
  }
  
  .page-thank-you .video-preview {
    margin-top: 40px;
  }
  
  .banner-logo {
    margin-bottom: 50px;
  }
  
  .video-preview-2 {
    margin-bottom: 0 !important;
    height: 250px;
    min-height: 300px;
    width: 400px;
  }
  
  /* =====================================================================
   *   helpers-placeholders.scss
   * =================================================================== */
  /* BG Gradients */
  /* Opacity Values */
  .opacity-05 {
    opacity: 0.05;
  }
  
  .opacity-10 {
    opacity: 0.10;
  }
  
  .opacity-15 {
    opacity: 0.15;
  }
  
  .opacity-20 {
    opacity: 0.20;
  }
  
  .opacity-25 {
    opacity: 0.25;
  }
  
  .opacity-30 {
    opacity: 0.30;
  }
  
  .opacity-35 {
    opacity: 0.35;
  }
  
  .opacity-40 {
    opacity: 0.40;
  }
  
  .opacity-45 {
    opacity: 0.45;
  }
  
  .opacity-50 {
    opacity: 0.50;
  }
  
  .opacity-55 {
    opacity: 0.55;
  }
  
  .opacity-60 {
    opacity: 0.60;
  }
  
  .opacity-65 {
    opacity: 0.65;
  }
  
  .opacity-70 {
    opacity: 0.70;
  }
  
  .opacity-75 {
    opacity: 0.75;
  }
  
  .opacity-80 {
    opacity: 0.80;
  }
  
  .opacity-85 {
    opacity: 0.85;
  }
  
  .opacity-90 {
    opacity: 0.90;
  }
  
  .opacity-95 {
    opacity: 0.95;
  }
  
  .opacity-100 {
    opacity: 1;
  }
  
  /**
    * -------------------------------------------------------------------------------
    * ( 04 ) - Content
    * -------------------------------------------------------------------------------
    *
    *  This part contains all content sections in main content
    *
    * ------
    *
    *  Content is divided to the following sections:
    *
    *    |
    *    |-->  [ 01 ] - Content
    *    |-->  [ 02 ] - Sidebar
    *    |-->  [ 03 ] - Section Our Services 1
    *    |-->  [ 04 ] - Section Our Services 2
    *    |-->  [ 05 ] - Section Some Facts 1
    *    |-->  [ 06 ] - Section Pricing Plans 1
    *    |-->  [ 07 ] - Section Customers Testimonials 1
    *    |-->  [ 08 ] - Section Clients 1
    *    |-->  [ 09 ] - Section CTA 1
    *
    */
  #content {
    position: relative;
    line-height: 1.8;
    background-color: #fff;
    z-index: 5;
    /* Important for icons animations */
  }
  
  #content-wrap {
    position: relative;
  }
  
  /*  [ 01 ] - Content
  ====================== */
  .content.sidebar-left {
    padding: 0 0 0 10px;
  }
  
  .page-single .section-content {
    padding-top: 0 !important;
    background-color: #fafafa;
  }
  
  .page-single .page-single-content {
    padding: 0 40px;
    box-shadow: 0 1px 4px rgba(0, 0, 0, 0.15);
    background-color: #fff;
  }
  
  .page-single .page-single-content .content {
    padding: 50px 0;
  }
  
  .page-single .content {
    padding: 30px;
  }
  
  .page-single.with-sidebar .content {
    padding-right: 40px;
    border-right: 1px solid #ccc;
  }
  
  @media (max-width: 992px) {
    .page-single.with-sidebar .content {
      padding-right: 0;
      border-right: none;
    }
  }
  
  /*  [ 02 ] - Sidebar
  ====================== */
  .sidebar {
    padding: 50px 0 50px 10px;
  }
  
  .box-widget + .box-widget {
    margin-top: 40px;
  }
  
  .box-widget .box-title {
    margin-bottom: 20px;
  }
  
  .box-widget .box-title::before {
    content: "";
    position: relative;
    top: 3px;
    display: inline-block;
    margin-left: 10px;
    width: 5px;
    height: 20px;
    background-color: var(--theme-color-1);
  }
  
  @media (max-width: 992px) {
    .sidebar {
      padding-top: 20px;
    }
  }
  
  /*  [ 03 ] - Section Our Services 1
  ===================================== */
  #section-our-services-1 .title-featured-1 {
    margin-bottom: 0;
  }
  
  /* Box Service 1 */
  .box-service-1 {
    display: flex;
    flex-direction: column;
    align-items: center;
    transition: all 0.2s;
  }
  
  .box-service-1 .box-icon {
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 0 0 22px;
    width: 80px;
    height: 80px;
    flex: 0 0 80px;
    font-size: 32px;
    border-radius: 999px;
    box-shadow: inset 0 0 0 0 transparent;
    background-color: rgba(134, 50, 96, 0.15);
    color: #222;
    transition: all 0.25s;
  }
  
  .box-service-1 .box-content {
    text-align: center;
  }
  
  .box-service-1:hover {
    transform: translateY(-10px);
  }
  
  .box-service-1:hover .box-icon {
    box-shadow: inset 0 0 0 40px #863260;
    color: #fff;
  }
  
  .box-service-1:hover h4 a {
    color: #863260;
  }
  
  /*  [ 04 ] - Section Our Services 2
  ===================================== */
  #section-our-services-2 {
    background-color: #f7f7f7;
  }
  
  #section-our-services-2 .pl-55 {
    padding-left: 55px !important;
  }
  
  @media (max-width: 768px) {
    #section-our-services-2 .pl-55 {
      padding-left: 15px !important;
    }
  }
  
  /* Box Contact Info 1 */
  .box-service-2 {
    display: flex;
  }
  
  .box-service-2 .box-icon {
    display: flex;
    align-items: flex-start;
    justify-content: flex-end;
    margin-right: 20px;
    margin-top: 5px;
    width: 30px;
    height: 30px;
    flex: 0 0 30px;
    font-size: 30px;
    color: #222;
    transition: all 0.25s;
  }
  
  .box-service-2 .box-content h4 {
    margin-bottom: 8px;
  }
  
  /* Video Preview */
  .video-preview {
    overflow: hidden;
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    min-height: 425px;
    border-radius: 10px;
    box-shadow: 5px 15px 28px 5px rgba(0, 0, 0, 0.18);
    background-color: #ddd;
  }
  
  @media (max-width: 992px) {
    .video-preview {
      min-height: 400px;
    }
  }
  
  @media (max-width: 768px) {
    .video-preview {
      min-height: 350px;
    }
  }
  
  @media (max-width: 480px) {
    .video-preview {
      min-height: 300px;
    }
  }
  
  .video-preview .img-bg {
    position: absolute;
    width: 100%;
    height: 100%;
  }
  
  .video-preview .btn-video {
    position: absolute;
    z-index: 2;
  }
  
  .video-preview .overlay-colored {
    transition: all 0.2s;
  }
  
  .video-preview:hover .overlay-colored {
    opacity: 0.6;
  }
  
  /* Button Video */
  .btn-video {
    position: relative;
    display: inline-flex;
    flex-direction: column;
    align-items: center;
    text-align: left;
  }
  
  .btn-video i {
    position: relative;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    width: 100px;
    height: 100px;
    text-align: center;
    font-size: 24px;
    border-radius: 100%;
    background: #863260;
    color: #fff;
    text-indent: 3px;
    transition: all 0.1s !important;
  }
  
  .btn-video i::before {
    position: relative;
    z-index: 1;
  }
  
  .btn-video i::after {
    content: "";
    position: absolute;
    left: 0;
    z-index: 0;
    width: 100%;
    height: 100%;
    border-radius: 100%;
    opacity: 0;
    transform: scale(0.5);
    background-color: #222;
    transition: all 0.3s;
  }
  
  .btn-video .title {
    display: inline-block;
    margin-top: 15px;
    font-size: 12px;
    font-weight: 600;
    letter-spacing: 2px;
    text-transform: uppercase;
    opacity: 0.5;
    color: #fff;
    transition: all 0.2s !important;
  }
  
  .btn-video:hover i {
    color: #fff;
  }
  
  .btn-video:hover i::after {
    transform: scale(1.02);
    opacity: 1;
  }
  
  .btn-video:hover .title {
    opacity: 1;
  }
  
  /* Button Video Animation */
  .btn-video i {
    box-shadow: 0 0 0 0 rgba(44, 250, 135, 0.5);
    animation: pulse 1.2s infinite;
  }
  
  @keyframes pulse {
    0% {
    }
    70% {
      box-shadow: 0 0 0 30px rgba(44, 250, 135, 0);
    }
    100% {
      box-shadow: 0 0 0 0 rgba(44, 250, 135, 0);
    }
  }
  
  /*  [ 05 ] - Section Some Facts 1
  =================================== */
  /* List Items */
  .list-boxes {
    list-style: none;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    margin: 0 -15px -30px;
  }
  
  .list-boxes.boxes-2 > li {
    width: 50%;
  }
  
  @media (max-width: 992px) {
    .boxes-2-md-2.list-boxes.boxes-2 > li {
      width: 50%;
    }
  }
  
  @media (max-width: 768px) {
    .boxes-2-md-2.list-boxes.boxes-2 > li {
      width: 100%;
    }
  }
  
  .list-boxes.boxes-3 > li {
    width: 33.33333%;
  }
  
  @media (max-width: 992px) {
    .list-boxes.boxes-3 > li {
      width: 50%;
    }
  }
  
  .list-boxes.boxes-4 > li {
    width: 25%;
  }
  
  @media (max-width: 992px) {
    .list-boxes.boxes-4 > li {
      width: 50%;
    }
  }
  
  .list-boxes > li {
    padding: 0 15px;
    margin-bottom: 30px;
  }
  
  @media (max-width: 768px) {
    .list-boxes.boxes-3 > li, .list-boxes.boxes-4 > li {
      width: 100%;
    }
    .list-boxes.boxes-3.mq-768 > li, .list-boxes.boxes-4.mq-768 > li {
      width: 100%;
    }
  }
  
  /* List Some Facts */
  @media (max-width: 992px) {
    .list-some-facts {
      margin-bottom: -40px;
    }
  }
  
  @media (max-width: 992px) {
    .list-some-facts > li {
      margin-bottom: 40px;
    }
  }
  
  @media (max-width: 600px) {
    .list-some-facts > li {
      width: 100% !important;
    }
  }
  
  /* Box Some Fact 1 */
  .box-some-fact-1 {
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
  }
  
  .box-some-fact-1 .box-icon {
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 0 0 10px;
    width: 50px;
    height: 50px;
    flex: 0 0 50px;
    font-size: 48px;
    color: #863260;
    transition: all 0.25s;
  }
  
  .box-some-fact-1 .box-content {
    width: 100%;
  }
  
  .box-some-fact-1 .box-content h2 {
    margin-bottom: -1px;
    font-size: 48px;
  }
  
  .box-some-fact-1 .box-content h5 {
    margin-bottom: 0;
    font-weight: 300;
    font-family: "Open Sans", "Almarai", sans-serif;
    color: #888;
  }
  
  .text-white.box-some-fact-1 .box-content h5 {
    opacity: 0.6;
    color: #fff;
  }
  
  /* Counter Stats */
  .counter-stats {
    line-height: 0.95;
  }
  
  .counter-stats .main {
    display: none;
  }
  
  .counter-stats .digit {
    position: relative;
    display: inline-block !important;
  }
  
  .counter-stats .digit > .digit-value {
    visibility: hidden;
    display: inline-block;
  }
  
  .counter-stats .digit .counter-animator {
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    overflow: hidden;
  }
  
  .counter-stats .digit .counter-animator ul {
    list-style: none;
    margin: 0;
    padding: 0;
    position: relative;
    transition: all 2s cubic-bezier(0.19, 1, 0.4, 1);
  }
  
  .counter-stats .digit .counter-animator ul li {
    margin-top: 0;
  }
  
  /*  [ 06 ] - Section Pricing Plans 1
  ====================================== */
  #section-pricing-plans-1 .section-title {
    margin-bottom: 50px;
  }
  
  #section-pricing-plans-1 .container-fluid {
    padding: 0 50px;
  }
  
  /* Pricing Table */
  .pricing-table {
    /* Column Header */
    /* Column Content */
    /* Column Footer */
  }
  
  @media (max-width: 992px) {
    .pricing-table [class*="col-"]:last-child {
      clear: both;
    }
  }
  
  .pricing-table .pt-column {
    overflow: hidden;
    position: relative;
    top: 0;
    padding: 50px 40px;
    border: none;
    box-shadow: inset 0 0 0 4px #ddd;
    border-radius: 10px;
    transition: all 0.3s;
  }
  
  .pricing-table .pt-column.featured {
    border: none;
    box-shadow: inset 0 0 0 4px #863260;
  }
  
  .pricing-table .pt-column.featured::before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    transition: all 0.2s;
  }
  
  .pricing-table .pt-column.featured .pt-column-header {
    border-bottom-color: rgba(255, 255, 255, 0.5);
  }
  
  .pricing-table .pt-column.featured .pt-column-header .icon,
  .pricing-table .pt-column.featured .pt-column-header .price h2 {
    color: #863260;
  }
  
  .pricing-table .pt-column.featured .pt-column-content i {
    color: #863260;
  }
  
  .pricing-table .pt-column:not(.featured):hover, .pricing-table .pt-column.featured:hover {
    transform: translateY(-15px);
    box-shadow: inset 0 0 0 4px #863260, 0 20px 28px 5px rgba(0, 0, 0, 0.1);
  }
  
  .pricing-table .pt-column:not(.featured):hover {
    box-shadow: inset 0 0 0 4px #888, 0 20px 28px 5px rgba(0, 0, 0, 0.1);
  }
  
  .pricing-table .pt-column:not(.featured):hover .pt-column-header .price h2 {
    color: #863260;
  }
  
  .pricing-table .pt-column-header {
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
  }
  
  .pricing-table .pt-column-header .icon {
    margin-bottom: 15px;
    font-size: 36px;
    color: #222;
  }
  
  .pricing-table .pt-column-header .title {
    font-size: 14px;
    font-family: "Titillium Web", "Almarai", sans-serif;
    font-weight: 600;
    text-transform: uppercase;
    letter-spacing: 3px;
    color: #aaa;
  }
  
  .pricing-table .pt-column-header .price {
    display: flex;
    flex-direction: column;
  }
  
  .pricing-table .pt-column-header .price h2 {
    margin-bottom: 0;
    font-size: 48px;
    transition: all 0.2s;
  }
  
  .pricing-table .pt-column-header .price .period {
    margin-top: -3px;
    font-size: 12px;
  }
  
  .pricing-table .pt-column-header .btn {
    margin-top: 25px;
  }
  
  .pricing-table .pt-column-content {
    list-style: none;
    margin-top: 35px;
    font-size: 18px;
  }
  
  .pricing-table .pt-column-content li {
    position: relative;
    display: flex;
    align-items: center;
  }
  
  .pricing-table .pt-column-content li + li {
    margin-top: 6px;
  }
  
  .pricing-table .pt-column-content li i {
    margin-left: auto;
    padding-left: 30px;
    color: #222;
  }
  
  /*  [ 07 ] - Section Customers Testimonials 1
  =============================================== */
  #section-customers-testimonials-1 {
    background-color: #f7f7f7;
  }
  
  /* Rating */
  .rating {
    display: flex;
    position: relative;
    color: #ff9831;
    font-size: 14px;
  }
  
  .rating i + i {
    margin-left: 2px;
  }
  
  /* Testimonial Single 1 */
  .testimonial-single-1 {
    position: relative;
    top: 0;
    text-align: center;
    transition: all 0.2s;
  }
  
  .highlighted .testimonial-single-1 {
    border-color: #863260;
    box-shadow: 0 0 50px 15px rgba(34, 34, 34, 0.05);
    background-color: #fff;
  }
  
  .testimonial-single-1 .rating {
    margin-top: 10px;
  }
  
  .testimonial-single-1.text-white {
    color: #fff;
  }
  
  .testimonial-single-1 .ts-content {
    position: relative;
    display: flex;
    justify-content: center;
    padding: 40px;
    font-size: 16px;
    font-weight: 300;
    border: 1px solid #863260;
    border-radius: 10px;
    background-color: #fff;
  }
  
  .testimonial-single-1 .ts-content .icon-quote {
    position: absolute;
    bottom: -20px;
    display: flex;
    justify-content: center;
    width: 40px;
    height: 40px;
    line-height: 59px;
    border-radius: 999px;
    font-size: 48px;
    color: #fff;
    background-color: #863260;
  }
  
  .testimonial-single-1 .ts-person {
    overflow: hidden;
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: 40px;
  }
  
  .testimonial-single-1 .ts-person h4 {
    display: block;
    margin-bottom: 0;
    text-transform: capitalize;
  }
  
  .testimonial-single-1 .ts-person span {
    display: block;
    font-size: 14px;
    color: #888;
  }
  
  .testimonial-single-1 .ts-img {
    overflow: hidden;
    width: 60px;
    height: 60px;
    border: 1px solid #ddd;
    border-radius: 999px;
  }
  
  .testimonial-single-1 .ts-name {
    margin-top: 10px;
  }
  
  /* Slider Testimonials */
  .slider-testimonials .slick-slider {
    overflow: hidden;
  }
  
  .slider-testimonials .slick-slider .slick-slide {
    transition: all 0.4s;
  }
  
  .slider-testimonials .slick-slider .slick-slide:not(.slick-active) {
    opacity: 0;
  }
  
  /*  [ 08 ] - Section Clients 1
  ================================ */
  #section-clients-1 {
    background-color: #863260;
  }
  
  /* Client Single */
  .client-single {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
  }
  
  .client-single img {
    position: relative;
    top: 0;
    width: auto !important;
    opacity: 1;
    transition: all 0.15s;
  }
  
  .client-single a:hover img {
    opacity: 0.6;
  }
  
  /* Slider Clients */
  .slider-clients .slick-slider {
    overflow: hidden;
  }
  
  .slider-clients .slick-slider .slick-track {
    display: flex;
    align-items: center;
  }
  
  .slider-clients .slick-slider .slick-list {
    margin: 0 -15px;
  }
  
  .slider-clients .slick-slider .slick-slide {
    padding: 0 15px;
    transition: all 0.4s;
  }
  
  /*  [ 09 ] - Section CTA 1
  ============================ */
  #section-cta-1 .section-content {
    padding: 140px 0;
  }
  
  @media (max-width: 768px) {
    #section-cta-1 .section-content {
      padding: 120px 0;
    }
  }
  
  #section-cta-1 h4 {
    font-weight: 500;
  }
  
  #section-cta-1 h1 {
    font-size: 50px;
    line-height: 1.2;
  }
  
  @media (max-width: 480px) {
    #section-cta-1 h1 {
      font-size: 35px;
    }
  }
  
  #section-cta-1 .description {
    font-size: 20px;
    font-weight: 300;
    opacity: 0.65;
  }
  
  @media (max-width: 480px) {
    #section-cta-1 .description {
      font-size: 18px;
    }
  }
  
  #section-cta-1.text-white h4 {
    opacity: 0.8;
  }
  
  #section-cta-1.text-white .description {
    color: #fff;
  }
  
  /* Center Vertical */
  .center-vertical .section-content > .container > .row {
    display: flex;
    align-items: center;
  }
  
  @media (max-width: 992px) {
    .center-vertical .section-content > .container > .row {
      display: block;
    }
  }
  
  .center-vertical .section-content > .container > .row > [class*="col-"] {
    display: flex;
    align-items: center;
  }
  
  .center-vertical .section-content > .container > .row > [class*="col-"] .box-center {
    width: 100%;
  }
  
  /**
   *  -------------------------------------------------------------------------------
   *  ( 05 ) - Footer
   *  -------------------------------------------------------------------------------
   *
   *  This part contains footer and mini footer
   *
   *  ------
   *
   *  It has the following code:
   *
   *    |
   *    |-->  [ 01 ] - Footer
   *    |-->  [ 02 ] - Footer Bars
   
   */
  /*  [ 01 ] - Footer
  ===================== */
  #footer {
    overflow: hidden;
    position: relative;
    z-index: 1;
  }
  
  /*  [ 02 ] - Footer Bars
  ========================== */
  /* Footer Bar */
  .footer-bar {
    padding: 100px 0;
    background: transparent;
  }
  
  .footer-bar .fb-row + .fb-row {
    padding-top: 50px;
    margin-top: 50px;
    border-top: 1px solid rgba(34, 34, 34, 0.05);
  }
  
  @media (max-width: 768px) {
    .footer-bar .fb-row {
      flex-direction: column;
    }
  }
  
  /* Footer Bar 1 */
  #footer-bar-1 {
    padding: 0;
  }
  
  #footer-bar-1 .footer-bar-wrap {
    width: 100%;
  }
  
  #footer-bar-1.footer-bar .fb-row {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    padding: 50px 0;
  }
  
  @media (max-width: 768px) {
    #footer-bar-1.footer-bar .fb-row {
      flex-direction: column;
      justify-content: center;
      padding: 40px 0;
    }
  }
  
  #footer-bar-1 .social-icons {
    justify-content: flex-end;
  }
  
  @media (max-width: 768px) {
    #footer-bar-1 .social-icons {
      margin-left: 0;
      margin-top: 15px;
    }
  }
  
  #footer-bar-1 .copyrights-message,
  #footer-bar-1 .social-icons {
    flex: 1;
  }
  
  /* Copyrights Message */
  .copyrights-message {
    font-size: 18px;
  }
  
  @media (max-width: 480px) {
    .copyrights-message {
      font-size: 16px;
    }
  }
  
  .copyrights-message a {
    opacity: 1;
    font-weight: 700;
    color: #222;
  }
  
  .copyrights-message a:hover {
    text-decoration: underline;
    color: #863260;
  }
  
  .text-white .copyrights-message {
    color: #fff;
  }
  
  /* =====================================================================
   *   helpers-placeholders.scss
   * =================================================================== */
  /* BG Gradients */
  /* Opacity Values */
  .opacity-05 {
    opacity: 0.05;
  }
  
  .opacity-10 {
    opacity: 0.10;
  }
  
  .opacity-15 {
    opacity: 0.15;
  }
  
  .opacity-20 {
    opacity: 0.20;
  }
  
  .opacity-25 {
    opacity: 0.25;
  }
  
  .opacity-30 {
    opacity: 0.30;
  }
  
  .opacity-35 {
    opacity: 0.35;
  }
  
  .opacity-40 {
    opacity: 0.40;
  }
  
  .opacity-45 {
    opacity: 0.45;
  }
  
  .opacity-50 {
    opacity: 0.50;
  }
  
  .opacity-55 {
    opacity: 0.55;
  }
  
  .opacity-60 {
    opacity: 0.60;
  }
  
  .opacity-65 {
    opacity: 0.65;
  }
  
  .opacity-70 {
    opacity: 0.70;
  }
  
  .opacity-75 {
    opacity: 0.75;
  }
  
  .opacity-80 {
    opacity: 0.80;
  }
  
  .opacity-85 {
    opacity: 0.85;
  }
  
  .opacity-90 {
    opacity: 0.90;
  }
  
  .opacity-95 {
    opacity: 0.95;
  }
  
  .opacity-100 {
    opacity: 1;
  }
  
  /**
   * File Name: style-switcher.css
   */
  .style-switcher {
    direction: ltr !important;
    width: 320px;
    height: 100%;
    text-align: center;
    background: #fff;
    box-shadow: 0 0 15px rgba(0, 0, 0, 0.1);
    font-family: "Open Sans", sans-serif;
    position: fixed;
    right: 0;
    top: 0;
    z-index: 21;
    transition: all 0.8s cubic-bezier(0.19, 1, 0.22, 1);
    -webkit-transform: translateX(100%);
    transform: translateX(100%);
    /* Scroll Settings */
  }
  
  .style-switcher .btn.purchase-btn {
    font-size: 16px;
    background: #82b440;
    border-color: #82b440;
    box-shadow: none;
  }
  
  .style-switcher .btn.dark,
  .style-switcher .btn.hover-dark:hover {
    background: #111;
    border-color: #111;
    box-shadow: none;
  }
  
  .style-switcher .ss-wrapper {
    width: calc(100% + 18px);
    padding-bottom: 25px;
  }
  
  .style-switcher .ss-scroll {
    border-radius: 0;
    background: rgba(0, 0, 0, 0.2);
  }
  
  .style-switcher.show {
    -webkit-transform: translateX(0);
    transform: translateX(0);
  }
  
  .style-switcher ul {
    list-style: outside none;
  }
  
  /* Style Switcher Icon */
  .ss-icon {
    color: #fff;
    background: #222;
    font-size: 25px;
    width: 55px;
    height: 55px;
    line-height: 50px;
    text-align: center;
    position: absolute;
    top: 211px;
    left: -55px;
    border-radius: 10px 0 0 10px;
    box-shadow: -2px 0 7px rgba(0, 0, 0, 0.2);
  }
  
  .ss-icon:hover, .ss-icon:focus {
    color: #fff;
  }
  
  .ss-icon.rotating * {
    -webkit-animation: ssIconRotate 5s linear infinite;
    -ms-animation: ssIconRotate 5s linear infinite;
    animation: ssIconRotate 5s linear infinite;
  }
  
  @-webkit-keyframes ssIconRotate {
    from {
      -webkit-transform: rotate(0deg);
      transform: rotate(0deg);
    }
    to {
      -webkit-transform: rotate(360deg);
      transform: rotate(360deg);
    }
  }
  
  @-ms-keyframes ssIconRotate {
    from {
      -webkit-transform: rotate(0deg);
      transform: rotate(0deg);
    }
    to {
      -webkit-transform: rotate(360deg);
      transform: rotate(360deg);
    }
  }
  
  @keyframes ssIconRotate {
    from {
      -webkit-transform: rotate(0deg);
      transform: rotate(0deg);
    }
    to {
      -webkit-transform: rotate(360deg);
      transform: rotate(360deg);
    }
  }
  
  /* Style Switcher Title */
  .style-switcher h3 {
    height: 75px;
    line-height: 75px;
    padding: 0 20px;
    margin: 0;
    font-size: 18px;
    text-align: center;
    word-spacing: 0px;
    font-weight: 600;
    background: #f9f9f9;
    letter-spacing: 0;
  }
  
  /* Style Switcher Content */
  .style-switcher-content {
    background: #fff;
    /*box-shadow: -3px 0 50px -2px rgba(0, 0, 0, 0.14);*/
    padding: 30px 25px;
    overflow-y: auto;
    height: calc(100% - 50px);
  }
  
  .style-switcher-content .ss-intro {
    margin-top: 20px;
  }
  
  .style-switcher-content p {
    font-size: 12px;
    line-height: 18px;
  }
  
  /* Style Switcher Content Box */
  .ss-content-box + .ss-content-box {
    margin-top: 30px;
    padding-top: 30px;
    border-top: 1px solid #eee;
  }
  
  .ss-content-box > h5 {
    margin-bottom: 15px;
    font-size: 15px;
  }
  
  .ss-content-box:last-child {
    margin-bottom: 0;
  }
  
  /* Colors Skins */
  .colors-skins {
    font-size: 0;
    margin-bottom: -5px;
    /* reversing "li" margin bottom */
  }
  
  .colors-skins li {
    margin: 0 5px 5px 0;
    display: inline-block;
    vertical-align: top;
  }
  
  .colors-skins li button {
    position: relative;
    display: block;
    width: 34px;
    height: 34px;
    padding: 0;
    border-radius: 9999px;
    border: none;
    outline: none;
    cursor: pointer;
    box-shadow: none;
    background: #eee;
  }
  
  .colors-skins li button:after {
    content: "";
    width: 100%;
    height: 100%;
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    border-radius: 9999px;
    opacity: 0;
    background: rgba(255, 255, 255, 0.3);
    transition: all 0.2s;
  }
  
  .colors-skins li button.active:after {
    width: 10px;
    /*  content: "\f00c";
    font-family: "fontAwesome";
    font-size: 20px;
    color: #fff;
  */
    height: 10px;
    top: 50%;
    left: 50%;
    opacity: 1;
    background: rgba(255, 255, 255, 0.55);
  }
  
  .colors-skins li button:hover:after {
    opacity: 1;
  }
  
  /* Default */
  .colors-skins li button.default {
    background: #863260;
  }
  
  /* Red */
  .colors-skins li button.red {
    background: #f9704f;
  }
  
  /* Green */
  .colors-skins li button.green {
    background: #1bc768;
  }
  
  /* Blue */
  .colors-skins li button.blue {
    background: #65a4e4;
  }
  
  /* Yellow */
  .colors-skins li button.yellow {
    background: #ffc527;
  }
  
  /* Orange */
  .colors-skins li button.orange {
    background: #f58b3c;
  }
  
  /* Brown */
  .colors-skins li button.brown {
    background: #da6540;
  }
  
  /* Light Green */
  .colors-skins li button.light-green {
    background: #1dcca9;
  }
  
  /* Lime */
  .colors-skins li button.lime {
    background: #86ba41;
  }
  
  /* Purple */
  .colors-skins li button.purple {
    background: #5753e4;
  }
  
  /* Turquoise */
  .colors-skins li button.turquoise {
    background: #0dc0c0;
  }
  
  /* Switch */
  .switch {
    text-align: center;
    font-size: 0;
  }
  
  .switch + .switch {
    margin-top: 5px;
  }
  
  .switch:last-child {
    margin-bottom: 0;
  }
  
  .switch span {
    font-size: 10px;
    line-height: 13px;
    position: relative;
    vertical-align: middle;
    text-transform: uppercase;
    display: inline-block;
    letter-spacing: 1px;
    width: 38.5%;
    transition: all 0.15s;
  }
  
  .switch span:first-child,
  .switch span:last-child {
    transition: all 0s;
    cursor: pointer;
  }
  
  .switch span:first-child {
    padding-right: 8px;
    text-align: right;
  }
  
  .switch span:last-child {
    padding-left: 8px;
    text-align: left;
  }
  
  .switch span.active {
    color: #222;
    font-weight: 600;
  }
  
  /* Switch Button */
  .switch-button {
    width: 45px !important;
    height: 24px;
    border: 1px solid #bbb;
    border-radius: 9999px;
    position: relative;
    display: inline-block;
    cursor: pointer;
  }
  
  .switch-button:after {
    content: "";
    position: absolute;
    left: 2px;
    top: 2px;
    width: 18px;
    height: 18px;
    background: #222;
    border-radius: 9999px;
    transition: all 0.15s;
  }
  
  .switch-button.active:after {
    left: 23px;
    background: #f17663;
  }
  
  /* Demos Preview */
  .demos-preview {
    font-size: 0;
  }
  
  .demos-preview li {
    vertical-align: top;
    margin-bottom: 15px;
  }
  
  .demos-preview li a {
    overflow: hidden;
    position: relative;
    display: block;
    max-height: 168px;
    width: calc(100% - 1px);
    border: 1px solid #e5e5e5;
    font-size: 10px;
    line-height: 13px;
    text-align: center;
    text-transform: uppercase;
    letter-spacing: 1px;
    background: #f1f1f1;
    color: #888;
  }
  
  .demos-preview li a::after,
  .demos-preview li a h5 {
    position: absolute;
    visibility: hidden;
    opacity: 0;
    transition: all 0.2s;
  }
  
  .demos-preview li a:hover::after,
  .demos-preview li a:hover h5 {
    visibility: visible;
    opacity: 1;
  }
  
  .demos-preview li a:hover h5 {
    transition-delay: 0.1s;
  }
  
  .demos-preview li a::after {
    top: 0;
    left: 0;
    content: "";
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.8);
  }
  
  .demos-preview li a h5 {
    z-index: 1;
    top: 50%;
    left: 50%;
    width: 100%;
    padding: 0 20px;
    text-align: center;
    text-transform: capitalize;
    transform: translate(-50%, -50%);
    font-size: 16px;
    color: #863260;
  }
  
  .demos-preview li a:hover {
    color: #222;
    background: #e1e1e1;
  }
  
  /* Demos Preview Image */
  .dp-img {
    position: absolute;
    left: calc(-100% - 10px);
    bottom: 150px;
    width: 100%;
    max-width: initial;
    border: 5px solid #fff;
    opacity: 0;
    visibility: hidden;
    transform: translateX(30px);
    transition: all 0.2s 0.3s;
    box-shadow: 0 0 15px rgba(0, 0, 0, 0.1);
    background: #ccc;
  }
  
  .dp-img.appeared {
    opacity: 1;
    visibility: visible;
    transform: translateX(0);
    transition: all 0.2s;
  }
  
  /* List Patterns */
  .list-patterns {
    list-style: none;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-flow: row wrap;
    margin-top: 20px;
    width: 100%;
    text-align: center;
  }
  
  .list-patterns li {
    display: inline-block;
    margin: 0 5px 5px 0;
  }
  
  .list-patterns li button {
    overflow: hidden;
    position: relative;
    display: block;
    width: 45px;
    height: 45px;
    padding: 0;
    border-radius: 9999px;
    border: none;
    outline: none;
    cursor: pointer;
  }
  
  .list-patterns li button.active::after {
    box-shadow: inset 0 0 0 3px rgba(5, 213, 97, 0.4);
  }
  
  .list-patterns li button::after {
    content: "";
    width: 100%;
    height: 100%;
    position: absolute;
    left: 0;
    top: 0;
    border-radius: 9999px;
    box-shadow: inset 0 0 0 1px rgba(0, 0, 0, 0.1);
    transition: all 0.2s;
  }
  
  .list-patterns li button img {
    display: block;
  }
  
  .colors-skins li button:after {
    content: "";
    width: 100%;
    height: 100%;
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    border-radius: 9999px;
    opacity: 0;
    background: rgba(255, 255, 255, 0.3);
    transition: all 0.2s;
  }
  
  .colors-skins li button.active:after {
    width: 10px;
    /*  content: "\f00c";
    font-family: "fontAwesome";
    font-size: 20px;
    color: #fff;
  */
    height: 10px;
    top: 50%;
    left: 50%;
    opacity: 1;
    background: rgba(255, 255, 255, 0.55);
  }
  
  .colors-skins li button:hover:after {
    opacity: 1;
  }
  
  /* Responsive case */
  @media (max-height: 720px) {
    .demos-preview li img {
      left: 293px;
    }
  }
  
  @media (max-width: 480px) {
    .style-switcher {
      display: none !important;
    }
  }
  
  /* Simple Scroll */
  .ss-wrapper {
    width: 100%;
    height: 100%;
    position: relative;
    z-index: 1;
    float: left;
    margin-left: -18px;
    width: calc(100% + 0);
    overflow-x: hidden;
  }
  
  .ss-wrapper > .ss-content {
    height: 100%;
    width: 100%;
    padding: 0 32px 0 0;
    position: relative;
    right: -18px;
    overflow: auto;
    box-sizing: border-box;
    padding: 0;
    overflow-x: hidden;
    overflow-y: scroll;
  }
  
  .ss-scroll {
    position: absolute;
    background-color: rgba(0, 0, 0, 0.1);
    width: 9px;
    border-radius: 4px;
    top: 0;
    z-index: 2;
    cursor: pointer;
    opacity: 0;
    transition: opacity 0.25s linear;
    right: 0 !important;
    width: 6px;
    transition: color 0.25s, opacity 0.25s;
  }
  
  .ss-hidden {
    display: none;
  }
  
  .ss-container:hover .ss-scroll {
    opacity: 1;
  }
  
  .ss-grabbed {
    -o-user-select: none;
    -ms-user-select: none;
    -moz-user-select: none;
    -webkit-user-select: none;
    user-select: none;
  }
  
  /*# sourceMappingURL=sourcemaps/style.css.map */